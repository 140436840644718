import {FormBaseClass} from "../../resources/elements/FormBaseClass";
import {translations} from "../../resources/classes/translations";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import {NitTools} from "../../resources/classes/NursitTools";
import {saveButton} from "../../resources/elements/save-button";
import {ConfigService} from "../../resources/services/ConfigService";
import {fhirEnums} from "../../resources/classes/fhir-enums";
import {PatientService} from "../../resources/services/PatientService";
import {IFormSetting} from "../../resources/classes/IFormSettings";
import {ReportService} from "../../resources/services/ReportService";
import {RuntimeInfo} from "../../resources/classes/RuntimeInfo";
import {QuestionnaireService} from "../../resources/services/QuestionnaireService";

const moment = require("moment");
import BundleType = fhirEnums.BundleType;
import HTTPVerb = fhirEnums.HTTPVerb;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

export class PflegeplanungIsolation extends FormBaseClass {
    notifierId: string = undefined;

    private _readonly: boolean = false;

    readonlyChanged(value: boolean) {
        this._readonly = value;
        if (value === false) {
            this.updateHeaderButtonClassName(this.response, 'mdi-edit');
        } else {
            this.updateHeaderButtonClassName(this.response);
        }
    }

    async selectLatestCompletedIso() {
        await QuestionnaireService.Fetch();
        this.previousIso = undefined;
        const cfg = await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
        const settingIsolation: IFormSetting = cfg.forms.find(o => o.route && o.route === 'isolation');
        const questionnaireIso = QuestionnaireService.GetQuestionnaireByNameDirect(settingIsolation.questionnaireName);

        let responses = this.patient.questionnaireResponses.filter(o => o
            && o.status && (o.status === 'completed' || o.status === 'amended' || o.status === 'in-progress')
            && o.questionnaire && o.questionnaire.reference && o.questionnaire.reference.indexOf(`/${questionnaireIso.id}`) > -1);

        let completedResponses = responses.filter(o => (o.status === 'completed' || o.status === 'amended'));

        // if no completed response is found, just take the in-progress ones
        if (completedResponses.length === 0 && responses.length > 0) {
            completedResponses = responses;
        }

        completedResponses.sort((a, b) => {
            return new Date(b.authored).valueOf() - new Date(a.authored).valueOf();
        });

        if (completedResponses[0]) {
            this.previousIso = completedResponses[0];
        }
    }

    async createButtonClicked(preset?): Promise<any> {
        return new Promise<any>(async (resolve) => {
            this.closeSaveButton();

            await this.selectLatestCompletedIso();

            resolve(await super.createButtonClicked(preset));
        });
    }

    previousIso: any;

    async beforeSaveNewItem(newItem: any) {
        await super.beforeSaveNewItem(newItem);

        if (this.previousIso) {
            newItem.item = NitTools.Clone(this.previousIso.item);
        }
        Fhir.Tools.UpdateAuthor(newItem, this.userService.practitioner);

        let datumItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(newItem, "iso_00", false);
        if (datumItem) {
            Fhir.QuestionnaireResponse.SetResponseItemCoding(datumItem, moment(new Date()).toJSON());
        }
    }

    get saveButtonIconClass(): string {
        let result = "";

        if (this.response) {
            if (this.tooOld)
                result = "mdi-alarm";
            else {
                if (this.readonly) {
                    result = "mdi-edit";
                } else {
                    if (this.response.status === "in-progress") {
                        result = "mdi-done-all";
                    } else {
                        result = "mdi-save";
                    }
                }
            }
        }

        return result;
    }

    override async afterResponseChanged(value: any) {
        await super.afterResponseChanged(value);

        this.readonly = true;
        let age = this.getResponseAgeInHours(value);
        let config: IFormSetting = ConfigService.GetFormSettings(ConfigService.FormNames.Isolation);
        if (this.response.status === "completed" || this.response.status === "amended") {
            this.tooOld = age >= config.expiration.default;
        } else {
            this.tooOld = false;
        }

        this.taskQueue.queueTask(() => this.closeSaveButton());
        this.selectLatestCompletedIso()
            .then(() => {
                this.responseBackup = NitTools.Clone(this.response.item);
                this.hasChanges = false;
            })
            .catch(err => console.warn(err));
    }

    afterCreate(result: any) {
        super.afterCreate(result);
        this.updateMarkState();
        this.taskQueue.queueTask(() => this.closeSaveButton());
    }

    __noResponsesText: string = undefined;

    get noIsoText(): string {
        if (!this.__noResponsesText && this.questionnaire) {
            let title = this.questionnaire.title || this.questionnaire.name;
            return this.i18n.tr("no_current_docu").replace(/%NAME%/g, title);
        }
    }

    get showNoResponseInfo(): boolean {
        if (!this.questionnaire) return false;
        return (this.responses || []).length === 0;
    }

    async updateMarkState() {
        await this.analyzeService.validateMarks(this.patient);
        this.notifier.notify(this.patient);
    }

    headerDateChanged($event) {
        this.response.authored = $event;
        console.warn("Changed DATE TO " + $event);
    }

    async detached() {
        document.body.classList.remove("no-toolbar-window");
        this.notifier.unsubscribe(this.notifierId);
        return super.detached();
    }

    displayStopDialog() {
        this.showStopDialog(this.response);
    }

    displayResponseInfo() {
        super.displayResponseInfo();
    }

    async attached() {
        this.invertQuestionnaireResponseOrder = true;
        this.route = ConfigService.FormNames.Isolation;

        document.body.classList.add("no-toolbar-window");

        this.notifierId = this.notifier.subscribe(() => {
            this.responses.sort((a: any, b: any) => {
                let d1 = new Date(a.authored);
                let d2 = new Date(b.authored);
                return d1.valueOf() - d2.valueOf();
            });
        });

        await super.attached();
        this.buttonText = translations.translate("new_isolation");

        for (const response of this.responses) {
            this.updateHeaderButtonClassName(response);
        }

        if (ConfigService.Debug) {
            window["isolation"] = this;
        }
    }

    async saveButtonStateChanged(sender: saveButton) {
        this.tooOld = sender.isTooOld;
        this.readonly = sender.buttonState !== "save";
    }

    getResponseAgeInHours(response): number {
        let result = moment(new Date()).diff(response.authored, "hours");
        //if (ConfigService.Debug) console.debug("RESPONSE IS " + result + " hours old");
        return result;
    }

    get btnClassResult(): string {
        return this.hasChanges ? 'btn-waring' : 'btn-default';
    }

    private updateHeaderButtonClassName(response: any, forcedClass: string = undefined) {
        if (!this.setting) this.setting = ConfigService.GetFormSettings('isolation');
        if (!this.setting || !response) return;

        const age = new moment(new Date()).diff(new Date(response.authored), 'h');

        let className = 'mdi-edit';
        if (this.setting.expiration && this.setting.expiration.default) {
            if (['amended', 'completed'].indexOf(response.status) > -1) {
                if (age > this.setting.expiration.default) {
                    className = 'mdi-lock';
                } else {
                    className = 'mdi-lock-open';
                }
            }

            className = forcedClass || className;
            response['_dateChangeStateClass'] = className;
        }

        /* window.requestAnimationFrame(() => {
            const i = document.querySelector(`.nav-tabs .tab-holder .tab-header i.mdi[data-response-id="${response.id}"]`);
            if (i) {
                i.setAttribute('class', 'mdi ' + className);
            }
        }) */
    }

    afterLoadedData() {
        super.afterLoadedData();

        if (this.autoLoadId) {
            let r = this.responses.find(o => o.id === this.autoLoadId);
            if (!r) return;

            window.setTimeout(() => {
                this.selectResponseId(r.id)
                    .then(() => this.selectedId = r.id);
            }, 500);
        }
    }

    selectResponseId(id): Promise<boolean> {
        if (this.response && this.response.id !== id) {
            this.readonly = this.response.status !== 'in-progress';
        }

        return super.selectResponseId(id);
    }

    async saveButtonClick() {
        if (!this.response || this.tooOld) return;
        if (this.readonly && !this.tooOld) {
            // just enable writing
            this.readonly = false;
            return;
        }

        if (!this.readonly && !this.tooOld) {
            RuntimeInfo.IsLoading = true;
            Fhir.Tools.UpdateAuthor(this.response, this.userService.practitioner);

            try {
                switch (this.response.status) {
                    case fhirEnums.QuestionnaireResponseStatus.inProgress:
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                        break;
                    case fhirEnums.QuestionnaireResponseStatus.completed:
                        this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                        break;
                }

                let bundle = [];
                // is editable and writeable, so ..
                // .. check mark 1 red ...
                if (this.patient.selectedAdditionalInfo) {
                    let latestIso = this.questionnaireService.getLatestResponseOfType(this.patient, this.qList.QIsolationId, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                    let setting = ConfigService.GetFormSettings("isolation");

                    if (setting && latestIso) {
                        this.analyzeService.validateMark1(this.patient);

                        let item1Red = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.patient.selectedAdditionalInfo, "mark_1_red");
                        if (item1Red) item1Red.answer = [{valueBoolean: this.patient.mark_1_red}];

                        bundle.push(this.patient.selectedAdditionalInfo);
                    }
                }

                if (this.patient.flags && (this.response.status === "completed" || this.response.status === "amended")) {
                    this.createLatestDocumentFlag();
                    bundle.push(this.patient.flags);
                }

                // .. update on server ..
                bundle.push(this.response);
                await this.fhirService.bundle(bundle, HTTPVerb.put, BundleType.batch);

                // .. and make readonly.
                this.readonly = this.response.status === "completed" || this.response.status === "amended";

                this.afterResponseChanged(this.response);
                if (this.response.status === fhirEnums.QuestionnaireResponseStatus.amended || this.response.status === fhirEnums.QuestionnaireResponseStatus.completed) {
                    let report = this.setting.report.autoSaveReportName || this.report;
                    ReportService.SendAutoSave(this.response.id, report, this.patient).catch(error => console.warn(error));
                }
            } catch (e) {
                this.dialogMessages.prompt(e.message || JSON.stringify(e), this.i18n.tr("warning"), true);
            } finally {
                this.hasChanges = false;
                this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined);
                RuntimeInfo.IsLoading = false;
            }
        }
    }

    async headerSaveButtonClicked(status: string): Promise<void> {
        // important: remember NOT to call super.headerSaveButtonClicked(status);
        this.isLoading = true;
        try {
            let dateChangeState = this.response['_dateChangeStateClass'];
            delete (this.response['_dateChangeStateClass']);

            await this.beforeSave();
            switch (status) {
                default:
                case "in-progress":
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.inProgress;
                    break;
                case "amended":
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.amended;
                    this.mayBeEdited = false;
                    this.readonly = true;
                    break;
                case "completed":
                    this.response.status = fhirEnums.QuestionnaireResponseStatus.completed;
                    this.mayBeEdited = false;
                    this.readonly = true;
                    break;
            }

            let bundleItems = [];

            if (this.userService.practitioner) {
                this.response.author = {reference: "Practitioner/" + this.userService.practitioner.id};
            }

            //if (!(originalStatus === this.response.status && !Fhir.QuestionnaireResponse.ItemValuesDiffer(this.responseBackup, this.response)))  {
            bundleItems.push(this.response);
            //}

            // so, as this is an isolation and if the status is completed, mark1 is intended to be set            
            if (["amended", "completed"].indexOf(this.response.status) > -1) {
                // run the validateMark1 to get whether the mark1_red is true
                this.analyzeService.validateMark1(this.patient);

                if (this.patient.flags) {
                    this.createLatestDocumentFlag();
                    bundleItems.push(this.patient.flags);
                }
            }

            //  bundleItems.push(this.patient.selectedAdditionalInfo);
            try {
                await this.fhirService.bundle(bundleItems, HTTPVerb.put, BundleType.batch);

                PatientService.AddQuestionnaireResponse(this.patient, this.response, true);
                PatientService.AddQuestionnaireResponse(this.patient, this.patient.selectedAdditionalInfo, true);
                this.responseBackup = NitTools.Clone(this.response ? this.response.item : undefined);

                await this.afterSave(this.response);

                this.response['_dateChangeStateClass'] = dateChangeState;
                // notify the application about the changes
            } catch (e) {
                this.dialogMessages.prompt(e, this.i18n.tr("warning"), true);
            }
        } catch (e) {
            console.warn(e);
            this.dialogMessages.prompt(e.message || JSON.stringify(e), this.i18n.tr("warning"), true);
        } finally {
            this.isLoading = false;
        }

// don't call this:
// return super.headerSaveButtonClicked(status);
    }


    dbg: boolean = false;

    async afterSave(response: any): Promise<void> {
        await super.afterSave(response);
        this.dbg = true;
        this.updateHeaderButtonClassName(this.response);
        this.notifier.notify(this.patient, this.patient.flags);
        this.notifier.notify(this.patient, this.patient);
    }

    async beforeSave(): Promise<any> {
        /* don't call: await super.beforeSave();
        if (ConfigService.Debug) {
            console.debug("Response:", this.response.item[1]);
            console.debug("BackupResponse:", this.responseBackup.item[1]);
        } */
    }
}
