import { autoinject, observable } from 'aurelia-framework';
import { DialogService } from "aurelia-dialog";
import { EventAggregator } from 'aurelia-event-aggregator';
import { fhirEnums } from "../../resources/classes/fhir-enums";
import { PatientService } from "../../resources/services/PatientService";
import { I18N } from "aurelia-i18n";
import * as Fhir from "../../resources/classes/FhirModules/Fhir";
import { UserService } from "../../resources/services/UserService";
import { FhirService } from "../../resources/services/FhirService";
import { NitTools } from "../../resources/classes/NursitTools";
import ModalPlanningTask from "../../resources/elements/modal-planning-task";
import { BasicForm } from "../../resources/elements/BasicForm";
import { ConfigService } from "../../resources/services/ConfigService";
import { DialogMessages } from "../../resources/services/DialogMessages";
import { PromptInput } from "../../resources/elements/prompt-input";
import { translations } from "../../resources/classes/translations";
import { CarePlanService } from "../../resources/services/CarePlanService";
import { ModalDiagnosis } from "../../resources/elements/modal-diagnosis";
import { PatientItem } from "../../resources/classes/Patient/PatientItem";
import RequestIntent = fhirEnums.RequestIntent;
import CarePlanStatus = fhirEnums.CarePlanStatus;
import RequestStatus = fhirEnums.RequestStatus;
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import ResourceType = fhirEnums.ResourceType;
import { Moment } from 'moment';
import { PermissionService } from 'resources/services/PermissionService';

const moment = require("moment");
const LAST_GENERATED_EXTENSION_URI = 'http://nursit-institute.com/fhir/extension/procedure-request-last-generated';

function eventPath(evt) {
    const target = evt.target;
    let path = (evt.composedPath && evt.composedPath()) || evt.path;

    if (path) {
        // Safari doesn't include Window, and it should.
        path = (path.indexOf(window) < 0) ? path.concat([window]) : path;
        return path;
    }

    if (target === window) {
        return [window];
    }

    function getParents(node, memo = []) {
        const parentNode = node.parentNode;

        if (!parentNode) {
            return memo;
        } else {
            return getParents(parentNode, memo.concat([parentNode]));
        }
    }

    return [target]
        .concat(getParents(target))
        .concat([window]);
}

@autoinject
export class Planning {
    config;
    encounterId;
    mappings;
    diagnosisCodeSystem;
    diagnoseToTasksConceptMap;
    assessment;
    latestAssessment;
    i18n: I18N;
    patientService: PatientService;
    userService: UserService;
    fhirService: FhirService;
    dialogService: DialogService;
    dialogMessages: DialogMessages;
    carePlanService: CarePlanService;
    permissionService: PermissionService;
    patient: PatientItem;
    eventAggregator: EventAggregator;
    isLoading = true;
    isRestricted = false;
    activeMappings = [];
    taskItems = [];
    activeTab = 1;
    codeSystem = null;
    carePlans = [];
    @observable activeCarePlan = null;
    cancelledActiveCarePlanChange = false;
    conditions: any[] = [];
    procedureRequests = [];
    showSaveMenu = false;
    showTemplatesMenu = false;
    _onclick = null;
    isDiagnosesUpdated = false;
    listURI = 'http://nursit-institute.com/fhir/StructureDefinition/list-template';
    listCode = 'procedure-requests';
    templatesList = [];
    isTrainee: boolean = false;
    isPrescriptionEnabled = false;
    searchMeasures = '';

    get eligibleProcedureRequests() {
        return this.procedureRequests.filter((pr) => !pr.isDeleted);
    }

    get eligibleProcedureRequestsNotInGroup() {
        return this.eligibleProcedureRequests.filter((pr) => !pr.isInGroup);
    }

    get isCarePlanReadOnly() {
        return !this.patient || this.patient.isOffline || !this.activeCarePlan || this.activeCarePlan.status !== CarePlanStatus.draft || !this.latestAssessment;
    }

    get isSaveMainDisabled() {
        return this.isCarePlanReadOnly;
    }

    get isSaveEnabled() {
        return (this.patient && !this.patient.isOffline) && !this.isDiagnosesOutdated && ((this.procedureRequests ? this.procedureRequests.find((pr) => pr.isAdded || pr.isDeleted || pr.isUpdated) : false) || this.isDiagnosesUpdated) && this.latestAssessment && !this.isLoading;
    }

    get isActivateEnabled() {
        return (this.patient && !this.patient.isOffline) && !this.isDiagnosesOutdated && !this.isThereProcedureRequestsWithNoMatchingDiagnosis && this.latestAssessment && !this.isLoading && !this.isCarePlanReadOnly;
    }

    get isNewDisabled() {
        return (!this.patient || this.patient.isOffline) || this.carePlans && this.carePlans[0] && this.carePlans[0].status === 'draft' || !this.latestAssessment || this.isLoading;
    }

    get isDiagnosesOutdated() {
        return (
            !this.isLoading &&
            CarePlanService.isDiagnosesOutdated(this.activeCarePlan, this.assessment, this.latestAssessment)
        );
    }

    get isThereProcedureRequestsWithNoMatchingDiagnosis() {
        return Boolean(this.eligibleProcedureRequests.find(pr => !pr.isInGroup));
    }

    get allDiagnosesDisabled() {
        return (!this.patient || this.patient.isOffline) || this.isCarePlanReadOnly || this.isDiagnosesOutdated || !this.activeCarePlan || (!this.patient || this.patient.isOffline);
    }

    constructor(i18n: I18N, patientService: PatientService, userService: UserService, fhirService: FhirService, dialogService: DialogService, dialogMessages: DialogMessages, carePlanService: CarePlanService, eventAggregator: EventAggregator, permissionService: PermissionService) {
        this.i18n = i18n;
        this.patientService = patientService;
        this.userService = userService;
        this.fhirService = fhirService;
        this.dialogService = dialogService;
        this.dialogMessages = dialogMessages;
        this.carePlanService = carePlanService;
        this.permissionService = permissionService;
        this.eventAggregator = eventAggregator;
        this.isTrainee = UserService.UserRole === 'trainee';

        this._onclick = (e) => {
            const path = eventPath(e);
            const findIgnoredSaveButton = path.find((el) => el.classList && el.classList.contains('save-button'));

            if (!findIgnoredSaveButton) {
                this.showSaveMenu = false;
            }

            const findIgnoredTemplatesButton = path.find((el) => el.classList && el.classList.contains('templates-button'));

            if (!findIgnoredTemplatesButton) {
                this.showTemplatesMenu = false;
            }
        };
    }

    activate(params) {
        this.encounterId = params.id;
    }

    async attached() {
        if (ConfigService.Debug) window["planning"] = this;

        this.config = ConfigService.GetFormSettings(ConfigService.FormNames.Planning);
        if (this.config) BasicForm.pageTitle = this.i18n.tr(this.config.title);

        document.body.classList.add("no-toolbar-window");
        document.addEventListener('click', this._onclick);

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.VIEW)) {
            this.isLoading = false;
            this.isRestricted = true;
            return;
        }

        this.isPrescriptionEnabled = this.config && this.config.settings && this.config.settings.allowPrescription;
        this.patient = await this.patientService.fetch(this.encounterId);
        this.codeSystem = await this.carePlanService.getGMTCodeSystem(this.patient);
        this.mappings = await this.carePlanService.getMappings(this.patient);
        this.diagnosisCodeSystem = await this.carePlanService.getDiagnoseCodeSystem(this.patient);
        this.diagnoseToTasksConceptMap = await this.carePlanService.getDiagnoseToTasksConceptMap(this.patient);
        this.latestAssessment = await this.carePlanService.loadLatestAssessment(this.patient);
        this.templatesList = await this.loadTemplatesList();

        if (!this.latestAssessment) {
            this.isLoading = false;
            return;
        }

        this.carePlans = await this.carePlanService.loadCarePlans(this.patient.encounterId);

        if (this.carePlans.length === 0) {
            this.isLoading = false;
        }
    }

    detached() {
        document.body.classList.remove("no-toolbar-window");
        document.removeEventListener('click', this._onclick);
    }

    async canDeactivate() {
        if (this.isSaveEnabled) {
            return new Promise((resolve) => {
                this.dialogMessages.dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('warning'), this.i18n.tr("yes"), this.i18n.tr("no"), true).whenClosed(result => {
                    resolve(!result.wasCancelled);
                });
            });
        }
    }

    async activeCarePlanChanged(newVal, oldVal) {
        if (this.isSaveEnabled && !this.cancelledActiveCarePlanChange) {
            this.isLoading = true;

            const dialog = await new Promise((resolve) => {
                this.dialogMessages.dialog(this.i18n.tr('confirm_discard_unsaved_changes'), this.i18n.tr('warning'), this.i18n.tr("yes"), this.i18n.tr("no"), true).whenClosed(result => {
                    resolve(!result.wasCancelled);
                });
            });

            if (!dialog) {
                this.cancelledActiveCarePlanChange = true;
                this.activeCarePlan = oldVal;
                this.isLoading = false;
                return;
            }
        } else if (this.cancelledActiveCarePlanChange) {
            this.cancelledActiveCarePlanChange = false;
            return;
        }

        this.selectMapping(null);
        this.isDiagnosesUpdated = false;

        if (this.activeCarePlan) {
            this.isLoading = true;

            this.assessment = await this.carePlanService.loadCarePlanAssessment(this.activeCarePlan, this.latestAssessment);
            this.conditions = await this.loadConditions();
            this.generateDiagnosisQuestionnaireItemsFromCurrentMapping();
            this.procedureRequests = await this.loadProcedureRequests();
            this.sortProcedureRequests();
            this.countActiveMappingItemTasks();

            this.isLoading = false;
        }
    }

    async loadConditions() {
        const conditions = [];
        const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

        if (this.activeCarePlan.addresses) {
            for (let i = 0; i < this.activeCarePlan.addresses.length; i++) {
                const id = this.activeCarePlan.addresses[i].reference.split('/')[1];

                bundleBuilder.add({
                    resourceType: ResourceType.condition,
                    id: id
                }, HTTPVerb.get, (condition) => {
                    conditions.push(condition);
                });
            }

            await bundleBuilder.exec();
        }

        return conditions;
    }

    async loadProcedureRequests() {
        const basedOn = `CarePlan/${this.activeCarePlan.id}`;
        const procedureRequests = await this.fhirService.fetch(`${(FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest)}?based-on=${basedOn}`);

        return procedureRequests
            .filter((pr) => {
                const legacyCode = pr.code.coding.find((coding) => coding.system.endsWith('/procedure-request'));

                return !(legacyCode && legacyCode.code.split('.')[0] == 99);
            })
            .filter((pr) => pr.intent !== RequestIntent.instanceOrder && pr.intent !== RequestIntent.order)
            .map((pr) => {
                const procedureRequest = CarePlanService.tempConvertProcedureRequest(this.patient, pr, this.codeSystem);
                const code = procedureRequest.code.coding[0].code;
                const codeData = CarePlanService.findCode(code, this.codeSystem);
                const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
                const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);

                if (!codeData) {
                    console.warn(`ProcedureRequest/ServiceRequest (${procedureRequest.id}) code ${code} not found in code system`);
                    return null;
                }

                if (!procedureRequest.occurrenceTiming.event) {
                    procedureRequest.occurrenceTiming.event = [];
                }

                return {
                    procedureRequest,
                    codeSystem: parsedCodeSystem,
                    duration: parsedDuration,
                    description: this.procedureDescription(procedureRequest, parsedCodeSystem, parsedDuration),
                    groupId: this.findProcedureRequestGroupId(procedureRequest),
                    isInGroup: this.isProcedureRequestInActiveMapping(procedureRequest),
                    isControlled: this.isProcedureRequestControlled(procedureRequest),
                    date: procedureRequest.occurrenceTiming.event[0],
                    isAdded: false,
                    isDeleted: false,
                    isUpdated: false
                };
            }).filter((pr) => pr !== null);
    }

    sortProcedureRequests() {
        this.procedureRequests.sort((a, b) => {
            const splitA = a.codeSystem.code.split('.');
            const splitB = b.codeSystem.code.split('.');

            while (splitA.length > 0) {
                if (!splitB[0]) return -1;

                if (parseInt(splitA[0], 10) > parseInt(splitB[0], 10)) {
                    return 1;
                } else if (parseInt(splitA[0], 10) < parseInt(splitB[0], 10)) {
                    return -1;
                }

                splitA.shift();
                splitB.shift();
            }

            return 0;
        });
    }

    findProcedureRequestGroupId(procedureRequest) {
        const code = procedureRequest.code.coding[0].code;
        let group = 0;

        this.mappings.forEach((mapping) => {
            if (mapping.taskIds && mapping.taskIds.indexOf(code) > -1 && group == 0) {
                group = mapping.groupId;
            }
        });

        return group;
    }

    isProcedureRequestInActiveMapping(procedureRequest) {
        const code = procedureRequest.code.coding[0].code;
        let contained = false;

        this.activeMappings.forEach((activeMapping) => {
            activeMapping.items.forEach((item) => {
                if (item.taskIds.indexOf(code) > -1) {
                    contained = true;
                }
            });
        });

        return contained;
    }

    isProcedureRequestControlled(procedureRequest) {
        return procedureRequest.category && procedureRequest.category.findIndex((ctg) => ctg.coding && ctg.coding[0].system === 'http://nursit-institute.com/fhir/StructureDefinition/procedure-request-type' && ctg.coding[0].code === 'controlled') > -1;
    }

    procedureDescription(procedureRequest, codeSystem, duration) {
        const weeksToLocale = {
            'mon': this.i18n.tr('day_1'),
            'tue': this.i18n.tr('day_2'),
            'wed': this.i18n.tr('day_3'),
            'thu': this.i18n.tr('day_4'),
            'fri': this.i18n.tr('day_5'),
            'sat': this.i18n.tr('day_6'),
            'sun': this.i18n.tr('day_7')
        };
        let description;

        if (procedureRequest.occurrenceTiming?.code?.coding?.find((coding) => coding.system === 'http://nursit-institute.com/fhir/StructureDefinition/timing-datatype')) {
            description = procedureRequest.occurrenceTiming.code.text;
        } else {
            switch (duration.type) {
                case 'interval': {
                    let unit;

                    if (duration.periodUnit === 'd') {
                        unit = this.i18n.tr('days');
                    } else if (duration.periodUnit === 'h') {
                        unit = this.i18n.tr('hours');
                    } else if (duration.periodUnit === 'min') {
                        unit = this.i18n.tr('minutes');
                    } else if (duration.periodUnit === 'wk') {
                        unit = this.i18n.tr('weeks');
                    }

                    if (duration.frequency > 0) {
                        description = this.i18n.tr('duration_frequency', { amount: duration.frequency, unit });
                    } else {
                        description = `alle ${duration.period} ${unit};`;
                    }

                    if (duration.periodUnit === 'wk' && procedureRequest.occurrenceTiming && procedureRequest.occurrenceTiming.repeat && procedureRequest.occurrenceTiming.repeat.dayOfWeek) {
                        description += ` [${procedureRequest.occurrenceTiming.repeat.dayOfWeek.map((day) => weeksToLocale[day]).join(', ')}]`;
                    }

                    break;
                }
                case 'as-needed': {
                    description = this.i18n.tr('0_whenNeeded') + ';';
                    break;
                }
                case 'at-admission': {
                    description = this.i18n.tr('0_atAdmission') + ';';
                    break;
                }
                case 'at-release': {
                    description = this.i18n.tr('0_atRelease') + ';';
                    break;
                }
                case 'everlasting': {
                    description = this.i18n.tr('0_everlasting') + ';';
                    break;
                }
            }

            description += ` ${procedureRequest.occurrenceTiming.repeat.duration} min;`;
        }

        return description;
    }

    loadTemplatesList() {
        return this.fhirService.fetch(`List?code=${this.listURI}|${this.listCode}`);
    }

    createCondition(diagnoseCode, diagnoseDisplay) {
        const condition: any = {
            id: 'urn:uuid:' + NitTools.Uid(),
            resourceType: ResourceType.condition,
            code: {
                coding: [{
                    code: diagnoseCode,
                    display: diagnoseDisplay,
                    system: CarePlanService.getDiagnoseCodeSystemUri(this.patient),
                }]
            },
            clinicalStatus: 'active',
            verificationStatus: 'provisional',
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
            subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
            [FhirService.FhirVersion > 3 ? 'recordedDate' : 'assertedDate']: moment().toJSON(),
            evidence: [{
                detail: [{
                    reference: `${ResourceType.questionnaireResponse}/${this.latestAssessment.id}`
                }]
            }]
        };

        if (this.userService.practitioner) {
            condition.asserter = {
                reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            };
        }

        return condition;
    }

    createConditions() {
        const conditions = [];

        this.mappings.forEach((mapping) => {
            const diagnosisItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.latestAssessment, mapping.assessmentId);

            if (diagnosisItem) {
                const diagnosisValue = String(Fhir.QuestionnaireResponse.GetResponseItemValue(diagnosisItem));

                if (mapping.assessmentValueId === diagnosisValue) {
                    conditions.push(this.createCondition(mapping.diagnoseID, mapping.diagnoseText));
                }
            }
        });

        return conditions;
    }

    getPersistentConditions() {
        const conditions = [];

        for (let i = 0; i < this.conditions.length; i++) {
            const condition = this.conditions[i];
            const coding = condition.code && condition.code.coding && condition.code.coding[0];

            if (coding) {
                const codeRoot = CarePlanService.findCodeRoot(coding.code, this.diagnosisCodeSystem);

                if (codeRoot) {
                    const isPersist = CarePlanService.getConceptProperty('persist', codeRoot);

                    if (isPersist) {
                        conditions.push(this.createCondition(coding.code, coding.display));
                    }
                }
            }
        }

        return conditions;
    }

    async createCarePlan() {
        if (this.isNewDisabled) {
            return;
        }

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.ADD)) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;
        this.carePlans = await this.carePlanService.loadCarePlans(this.patient.encounterId);

        const draftCarePlan = this.carePlans.find((carePlan) => carePlan.status === fhirEnums.CarePlanStatus.draft);

        if (draftCarePlan) {
            this.activeCarePlan = draftCarePlan;
            this.isLoading = false;
            return;
        }

        const conditions = this.createConditions();

        conditions.push(...this.getPersistentConditions());

        const skeleton: any = {
            id: 'urn:uuid:' + NitTools.Uid(),
            resourceType: ResourceType.carePlan,
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: { reference: `${ResourceType.encounter}/${this.patient.encounterId}` },
            intent: fhirEnums.CarePlanIntent.plan,
            status: fhirEnums.CarePlanStatus.draft,
            subject: { reference: `${ResourceType.patient}/${this.patient.id}` },
            supportingInfo: [
                { reference: `${ResourceType.questionnaireResponse}/${this.latestAssessment.id}` }
            ],
            addresses: conditions.map((condition) => ({ reference: `Condition/${condition.id}` }))
        };

        if (this.userService.practitioner) {
            skeleton.author = [{
                reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }

        this.conditions = [];

        const activatedCarePlan = this.carePlans.find((carePlan) => carePlan.status === CarePlanStatus.active);
        const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);
        const now = moment().toJSON();

        bundleBuilder.add(skeleton, HTTPVerb.post, (item) => {
            this.carePlans.unshift(item);
            this.activeCarePlan = item;
        });

        conditions.forEach((condition) => {
            bundleBuilder.add(condition, HTTPVerb.post, (item) => {
                this.conditions.push(item);
            });
        });

        if (activatedCarePlan) {
            const procedureRequestCodes = {};

            for (let i = 0; i < this.procedureRequests.length; i++) {
                const pr = this.procedureRequests[i];
                const procedureRequest = pr.procedureRequest;
                const procedureRequestId = procedureRequest.id;
                const findLastProcedureRun = procedureRequest.extension && procedureRequest.extension.findIndex((ext) => ext.url === LAST_GENERATED_EXTENSION_URI);

                if (procedureRequestCodes[pr.codeSystem.code]) {
                    continue;
                } else {
                    procedureRequestCodes[pr.codeSystem.code] = true;
                }

                procedureRequest.id = NitTools.Uid();
                procedureRequest.status = RequestStatus.draft;
                procedureRequest.authoredOn = now;
                procedureRequest.basedOn = [
                    { reference: skeleton.id },
                    { reference: `${procedureRequest.resourceType}/${procedureRequestId}` }
                ];

                if (findLastProcedureRun > -1) {
                    procedureRequest.extension.splice(findLastProcedureRun, 1);
                }

                if (this.userService.practitioner) {
                    procedureRequest.performer = {
                        reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                        display: this.userService.fullNameOrUsername
                    };
                } else {
                    delete procedureRequest.performer;
                }

                procedureRequest.occurrenceTiming.event = [null];

                bundleBuilder.add(procedureRequest, HTTPVerb.post, (item) => pr.procedureRequest = item);
            }
        }

        await bundleBuilder.exec();
        this.isLoading = false;
    }

    generateDiagnosisQuestionnaireItemsFromCurrentMapping() {
        this.activeMappings = [];

        this.diagnosisCodeSystem.concept.forEach((group) => {
            if (!group.concept) {
                return;
            }

            const groupAutoSelect = group.property?.find((prop) => prop.code === 'autoSelect' && prop.valueBoolean === true);

            group.concept.forEach((category) => {
                if (!category.concept) {
                    return;
                }

                const categoryAutoSelect = category.property?.find((prop) => prop.code === 'autoSelect' && prop.valueBoolean === true);

                category.concept.forEach((subCat) => {
                    const subCatAutoSelect = subCat.property?.find((prop) => prop.code === 'autoSelect' && prop.valueBoolean === true);
                    const subCatCode = subCat.code;
                    const subCatDisplay = subCat.display;

                    if (groupAutoSelect || categoryAutoSelect || subCatAutoSelect) {
                        const groupId = group.code;
                        const groupTitle = group.display;
                        const taskIdsConcept = CarePlanService.findConceptMapping(subCatCode, this.diagnoseToTasksConceptMap);
                        const taskIds = taskIdsConcept ? taskIdsConcept.map((task) => task.code) : [];

                        let curDisplay = this.activeMappings.find(o => o.id == groupId);

                        if (!curDisplay) {
                            const isFirstAdded = this.activeMappings.length === 0;

                            curDisplay = {
                                internalId: NitTools.UidName(),
                                id: groupId,
                                title: groupTitle,
                                items: [],
                                itemsShown: isFirstAdded,
                                active: false,
                                itemTasksActive: 0
                            };

                            this.activeMappings.push(curDisplay);
                        }

                        if (taskIds.length > 0) {
                            const existingItem = curDisplay.items.find((item) => item.diagnoseID === subCatCode);

                            if (!existingItem) {
                                curDisplay.items.push({
                                    diagnoseID: subCatCode,
                                    diagnoseText: `${category.display} ${subCatDisplay}`,
                                    taskIds: taskIds,
                                    active: false,
                                    tasksActive: 0
                                });
                            }
                        } else {
                            console.warn(`${groupId} has no matching task IDs for autoselect`);
                        }
                    }
                });
            });
        });

        this.conditions.forEach((condition) => {
            const conditionCode = condition.code && condition.code.coding && condition.code.coding[0].code;
            const conditionDisplay = condition.code && condition.code.coding && condition.code.coding[0].display;

            if (!conditionCode) {
                return;
            }

            const { groupId, groupTitle } = this.findCategoryOfDiagnosis(condition);
            const taskIds = this.findTasksIdsOfDiagnosis(condition);

            let curDisplay = this.activeMappings.find(o => o.id == groupId);

            if (!curDisplay) {
                const isFirstAdded = this.activeMappings.length === 0;

                curDisplay = {
                    internalId: NitTools.UidName(),
                    id: groupId,
                    title: groupTitle,
                    items: [],
                    itemsShown: isFirstAdded,
                    active: false,
                    itemTasksActive: 0
                };

                this.activeMappings.push(curDisplay);
            }

            if (taskIds.length > 0) {
                const existingItem = curDisplay.items.find((item) => item.diagnoseID === conditionCode);

                if (!existingItem) {
                    curDisplay.items.push({
                        diagnoseID: conditionCode,
                        diagnoseText: conditionDisplay,
                        taskIds: taskIds,
                        active: false,
                        tasksActive: 0
                    });
                }
            } else {
                console.warn(`${conditionCode} has no matching task IDs`);
            }
        });

        this.activeMappings = this.activeMappings.filter((mapping) => mapping.items.length > 0);
    }

    findCategoryOfDiagnosis(condition) {
        const conditionCode = condition.code && condition.code.coding && condition.code.coding[0].code;
        let groupId, groupTitle;

        this.diagnosisCodeSystem.concept.forEach((group) => {
            if (!group.concept) {
                return;
            }

            group.concept.forEach((category) => {
                if (!category.concept) {
                    return;
                }

                category.concept.forEach((subCat) => {
                    if (conditionCode === subCat.code) {
                        groupId = group.code;
                        groupTitle = group.display;
                    }
                });
            });
        });

        return {
            groupId,
            groupTitle
        };
    }

    findTasksIdsOfDiagnosis(condition) {
        const conditionCode = condition.code && condition.code.coding && condition.code.coding[0].code;
        const taskIds = CarePlanService.findConceptMapping(conditionCode, this.diagnoseToTasksConceptMap);

        return !taskIds ? [] : taskIds.map((task) => task.code);
    }

    changeTab(name) {
        if (name === 'measures') {
            let firstFound = false;

            this.activeMappings.forEach((activeMapping) => {
                activeMapping.items.forEach((item) => {
                    if (item.active && !firstFound) {
                        firstFound = true;

                        this.selectMapping(item, true);
                    } else {
                        item.active = false;
                    }
                });
            });

            if (!firstFound) {
                this.selectMapping(null, true);
            }
        } else if (name === 'overview') {
            this.activeTab = 1;
        }
    }

    toggleGroup(activeMapping) {
        activeMapping.itemsShown = !activeMapping.itemsShown;
    }

    foldProcedures() {
        this.activeMappings.forEach((activeMapping) => activeMapping.itemsShown = false);
    }

    unfoldProcedures() {
        this.activeMappings.forEach((activeMapping) => activeMapping.itemsShown = true);
    }

    selectMapping(item, switchTab = false) {
        if (switchTab) {
            this.activeTab = 0;
        }

        this.searchMeasures = '';
        this.activeMappings.forEach((activeMapping) => {
            activeMapping.items.forEach((itm) => {
                itm.active = false;
            });
        });

        this.taskItems = [];

        if (item) {
            item.active = true;
            item.taskIds.forEach((id) => {
                const code = CarePlanService.parseCodeSystem(CarePlanService.findCode(id, this.codeSystem));

                if (code) {
                    const taskProcedureRequest = this.procedureRequests.find((pr) => !pr.isDeleted && pr.codeSystem.code === code.code);

                    this.taskItems.push({
                        code: code.code,
                        display: code.display,
                        durations: code.durations,
                        properties: code.properties,
                        hasActiveTask: Boolean(taskProcedureRequest),
                        isControlled: taskProcedureRequest ? this.isProcedureRequestControlled(taskProcedureRequest.procedureRequest) : false
                    });
                }
            });
        }
    }

    get filteredTaskItems() {
        return this.taskItems.filter((task) => task.display.toLowerCase().includes(this.searchMeasures.toLowerCase()));
    }

    goVarProcedure(event, task) {
        event.preventDefault();
        event.stopPropagation();

        window.open(`https://www.varportal.de/portal/procedure/${task.properties.varProcedure}`, '_blank');
    }

    countActiveMappingItemTasks() {
        this.activeMappings.forEach((activeMapping) => {
            activeMapping.itemTasksActive = 0;

            activeMapping.items.forEach((item) => {
                item.tasksActive = 0;

                this.procedureRequests.forEach((pr) => {
                    if (!pr.isDeleted && item.taskIds.indexOf(pr.codeSystem.code) > -1) {
                        item.tasksActive++;
                    }
                });

                if (item.tasksActive > 0) {
                    activeMapping.itemTasksActive++;
                }
            });
        });
    }

    clickItemMeasure(task) {
        if (this.isCarePlanReadOnly) {
            return;
        }

        if (task.hasActiveTask) {
            this.dialogMessages.dialog(this.i18n.tr('confirm_remove_measure'), this.i18n.tr('warning'), this.i18n.tr("yes"), this.i18n.tr("no"), true).whenClosed(result => {
                if (!result.wasCancelled) {
                    this.procedureRequests.filter((pr) => pr.codeSystem.code === task.code).forEach((pr) => {
                        pr.isDeleted = true;
                    });
                    task.hasActiveTask = false;
                    task.isControlled = false;
                    this.countActiveMappingItemTasks();
                }
            });
        } else {
            this.dialogService.open({
                viewModel: ModalPlanningTask,
                model: {
                    task,
                    carePlan: this.activeCarePlan,
                    codeSystem: CarePlanService.parseCodeSystem(CarePlanService.findCode(task.code, this.codeSystem)),
                    patient: this.patient
                },
                lock: true
            }).whenClosed(response => {
                if (!response.wasCancelled) {
                    const procedureRequest = response.output;
                    const code = procedureRequest.code.coding[0].code;
                    const codeData = CarePlanService.findCode(code, this.codeSystem);
                    const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
                    const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);

                    if (!codeData) {
                        console.warn(`ProcedureRequest/ServiceRequest code ${code} not found in code system`);
                        return;
                    }

                    this.procedureRequests.push({
                        procedureRequest,
                        codeSystem: parsedCodeSystem,
                        duration: parsedDuration,
                        description: this.procedureDescription(procedureRequest, parsedCodeSystem, parsedDuration),
                        groupId: this.findProcedureRequestGroupId(procedureRequest),
                        isInGroup: this.isProcedureRequestInActiveMapping(procedureRequest),
                        isControlled: this.isProcedureRequestControlled(procedureRequest),
                        date: procedureRequest.occurrenceTiming.event[0],
                        isAdded: true,
                        isDeleted: false,
                        isUpdated: false
                    });

                    task.hasActiveTask = true;
                    task.isControlled = this.isProcedureRequestControlled(procedureRequest);
                    this.sortProcedureRequests();
                    this.countActiveMappingItemTasks();
                }
            });
        }
    }

    editProcedure(pr) {
        if (this.isCarePlanReadOnly) {
            return;
        }

        this.dialogService.open({
            viewModel: ModalPlanningTask,
            model: {
                carePlan: this.activeCarePlan,
                procedureRequest: pr.procedureRequest,
                codeSystem: pr.codeSystem,
                patient: this.patient
            },
            lock: true
        }).whenClosed(response => {
            if (!response.wasCancelled) {
                const updatedProcedureRequest = response.output;

                if (!updatedProcedureRequest) {
                    pr.isDeleted = true;

                    this.taskItems.forEach((task) => {
                        const taskProcedureRequest = this.procedureRequests.find((pr) => !pr.isDeleted && pr.codeSystem.code === task.code);

                        task.hasActiveTask = Boolean(taskProcedureRequest);
                        task.isControlled = taskProcedureRequest ? this.isProcedureRequestControlled(taskProcedureRequest.procedureRequest) : false;
                    });
                } else {
                    pr.isUpdated = true;
                    pr.procedureRequest = updatedProcedureRequest;
                    pr.isControlled = this.isProcedureRequestControlled(updatedProcedureRequest);
                    pr.duration = CarePlanService.parseCodeSystemDuration(updatedProcedureRequest.occurrenceTiming);
                    pr.description = this.procedureDescription(updatedProcedureRequest, pr.codeSystem, pr.duration);
                    pr.date = updatedProcedureRequest.occurrenceTiming.event[0];
                }

                this.countActiveMappingItemTasks();
            }
        });
    }

    openSaveMain() {
        if (this.isSaveMainDisabled) {
            return;
        }

        this.showSaveMenu = !this.showSaveMenu;
    }

    openTemplatesMenu() {
        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.TEMPLATES.VIEW)) {
            this.isLoading = false;
            return;
        }

        this.showTemplatesMenu = !this.showTemplatesMenu;
    }

    selectDiagnosesFromProcedure(pr) {
        const procedureRequest = pr.procedureRequest;
        const code = procedureRequest.code.coding[0].code;

        this.activeMappings.forEach((activeMapping) => {
            activeMapping.items.forEach((item) => {
                item.active = false;
            });
        });

        this.activeMappings.forEach((activeMapping) => {
            activeMapping.items.forEach((item) => {
                if (item.taskIds.indexOf(code) > -1) {
                    if (!activeMapping.itemsShown) {
                        activeMapping.itemsShown = true;
                    }

                    item.active = true;
                }
            });
        });
    }

    async fixOutdatedDiagnoses() {
        if (this.activeCarePlan.status !== CarePlanStatus.draft) {
            return;
        }

        this.isLoading = true;

        const checkOutdated = await this.checkIsCarePlanOutdated();

        if (checkOutdated.outdated) {
            this.dialogMessages.prompt(this.i18n.tr('planning_outdated')).whenClosed(async () => {
                this.carePlans = checkOutdated.carePlans;
                this.activeCarePlan = checkOutdated.carePlan;
                this.isLoading = false;
            });
            return;
        }

        this.assessment = this.latestAssessment;

        const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

        for (let i = 0; i < this.conditions.length; i++) {
            bundleBuilder.add(this.conditions[i], HTTPVerb.delete);
        }

        const conditions = this.createConditions();

        this.activeCarePlan.addresses = [];
        this.conditions = [];

        conditions.forEach((condition) => {
            this.activeCarePlan.addresses.push({ reference: `Condition/${condition.id}` });

            bundleBuilder.add(condition, HTTPVerb.post, (item) => {
                this.conditions.push(item);
            });
        });

        await this.saveCarePlan(bundleBuilder);

        this.generateDiagnosisQuestionnaireItemsFromCurrentMapping();

        this.procedureRequests.forEach((pr) => {
            pr.isInGroup = this.isProcedureRequestInActiveMapping(pr.procedureRequest);
            pr.isControlled = this.isProcedureRequestControlled(pr.procedureRequest);
        });

        this.countActiveMappingItemTasks();
    }

    async saveCarePlanButton() {
        if (!this.isSaveEnabled) {
            return;
        }

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.SAVE)) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;

        const checkOutdated = await this.checkIsCarePlanOutdated();

        if (checkOutdated.outdated) {
            this.dialogMessages.prompt(this.i18n.tr('planning_outdated')).whenClosed(async () => {
                this.carePlans = checkOutdated.carePlans;
                this.activeCarePlan = checkOutdated.carePlan;
                this.isLoading = false;
            });
            return;
        }

        this.saveCarePlan();
    }

    async saveCarePlan(bundleBuilder = null) {
        if (!bundleBuilder) {
            bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);
        }

        this.isLoading = true;

        this.activeCarePlan.supportingInfo = [
            { reference: `${ResourceType.questionnaireResponse}/${this.assessment.id}` },
        ];

        if (this.userService.practitioner) {
            this.activeCarePlan.author = [{
                reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                display: this.userService.fullNameOrUsername
            }];
        }

        bundleBuilder.add(this.activeCarePlan, HTTPVerb.put, (item) => {
            this.activeCarePlan.addresses = item.addresses;
            this.activeCarePlan.meta = item.meta;
        });

        this.isDiagnosesUpdated = false;

        for (let i = 0; i < this.procedureRequests.length; i++) {
            const pr = this.procedureRequests[i];
            const procedureRequest = pr.procedureRequest;

            if (pr.isDeleted) {
                if (!pr.isAdded) {
                    bundleBuilder.add(procedureRequest, HTTPVerb.delete);
                }
            } else if (pr.isAdded) {
                bundleBuilder.add(procedureRequest, HTTPVerb.post, (item) => {
                    pr.procedureRequest = item;
                    pr.isAdded = false;

                    if (!pr.procedureRequest.occurrenceTiming.event) {
                        pr.procedureRequest.occurrenceTiming.event = [];
                    }
                });
            } else if (pr.isUpdated) {
                bundleBuilder.add(procedureRequest, HTTPVerb.put, (item) => {
                    pr.procedureRequest = item;
                    pr.isUpdated = false;

                    if (!pr.procedureRequest.occurrenceTiming.event) {
                        pr.procedureRequest.occurrenceTiming.event = [];
                    }
                });
            }
        }

        this.procedureRequests = this.procedureRequests.filter((pr) => !pr.isDeleted);

        await bundleBuilder.exec();

        this.carePlans.sort(CarePlanService.sortCarePlans);
        this.isLoading = false;
    }

    async activateCarePlan() {
        if (this.isTrainee || !this.isActivateEnabled)
            return;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.CONFIRM)) {
            this.isLoading = false;
            return;
        }

        if (this.patient.encounter.period && this.patient.encounter.period.end && moment(this.patient.encounter.period.end).isBefore(moment())) {
            // @ts-ignore
            this.dialogMessages.prompt(this.i18n.tr('careplan_activate_fail_patient_discharged', { date: moment(this.patient.encounter.period.end).format('DD.MM.YYYY HH:mm') }));
            return;
        }

        // noinspection JSUnusedLocalSymbols
        function repeat(length, str) {
            const arr = [];

            for (let i = 0; i < length; ++i) {
                arr.push(str);
            }

            return arr;
        }

        const checkOutdated = await this.checkIsCarePlanOutdated();

        if (checkOutdated.outdated) {
            this.dialogMessages.prompt(this.i18n.tr('planning_outdated')).whenClosed(async () => {
                this.carePlans = checkOutdated.carePlans;
                this.activeCarePlan = checkOutdated.carePlan;
                this.isLoading = false;
            });
            return;
        }

        if (!this.isActivateEnabled) {
            return;
        }

        if (this.activeCarePlan.status !== CarePlanStatus.draft) {
            this.dialogMessages.prompt(this.i18n.tr('planning_already_completed', { status: `careplan_status_${this.activeCarePlan.status}` })).whenClosed(async () => {
                this.isLoading = false;
            });
            return;
        }

        this.dialogMessages.dialog(this.i18n.tr('confirm_activate_plan'), this.i18n.tr('warning'), this.i18n.tr("yes"), this.i18n.tr("no"), true).whenClosed(async (result) => {
            if (!result.wasCancelled) {
                const now = moment();
                const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

                this.isLoading = true;

                const checkOutdated = await this.checkIsCarePlanOutdated();

                if (checkOutdated.outdated) {
                    this.dialogMessages.prompt(this.i18n.tr('planning_outdated')).whenClosed(async () => {
                        this.carePlans = checkOutdated.carePlans;
                        this.activeCarePlan = checkOutdated.carePlan;
                        this.isLoading = false;
                    });
                    return;
                }

                await this.saveCarePlan();

                this.isLoading = true;

                const previousActivePlans = checkOutdated.carePlans.filter((carePlan) => carePlan.status === fhirEnums.CarePlanStatus.active);

                // Complete previously active CarePlans
                if (previousActivePlans.length > 0) {
                    for (let i = 0; i < previousActivePlans.length; i++) {
                        const previousActivePlan = previousActivePlans[i];

                        previousActivePlan.status = fhirEnums.CarePlanStatus.completed;

                        if (!previousActivePlan.period) {
                            previousActivePlan.period = { start: previousActivePlan.meta.lastUpdated };
                        }

                        previousActivePlan.period.end = moment(now).subtract(1, 'second').toJSON();

                        bundleBuilder.add(previousActivePlan, HTTPVerb.put, (item) => previousActivePlan.meta = item.meta);

                        const procedureRequests = await this.fhirService.fetch(`${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}?based-on=${ResourceType.carePlan}/${previousActivePlan.id}`);

                        for (let i = 0; i < procedureRequests.length; i++) {
                            const procedureRequest = procedureRequests[i];
                            procedureRequest.status = fhirEnums.RequestStatus.completed;

                            if (!procedureRequest.occurrenceTiming.event) {
                                procedureRequest.occurrenceTiming.event = [previousActivePlan.meta.lastUpdated];
                            }

                            procedureRequest.occurrenceTiming.event[1] = moment(now).subtract(1, 'second').toJSON();

                            bundleBuilder.add(procedureRequest, HTTPVerb.put);

                            let procedures = await this.fhirService.fetch(`${ResourceType.procedure}?based-on=${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequest.id}&date=ge${now.toJSON()}&status=preparation`);

                            for (let j = 0; j < procedures.length; j++) {
                                const procedure = procedures[j];

                                bundleBuilder.add(procedure, HTTPVerb.delete);
                            }
                        }

                        if (previousActivePlan.addresses) {
                            for (let i = 0; i < previousActivePlan.addresses.length; i++) {
                                try {
                                    const condition: any = <any>await this.fhirService.get(previousActivePlan.addresses[i].reference);

                                    condition.clinicalStatus = 'inactive';

                                    bundleBuilder.add(condition, HTTPVerb.put);
                                } catch (e) {
                                }
                            }
                        }
                    }
                }

                // Set CarePLan Active
                this.activeCarePlan.status = fhirEnums.CarePlanStatus.active;
                this.activeCarePlan.period = { start: now.toJSON() };

                if (this.userService.practitioner) {
                    this.activeCarePlan.author = [{
                        reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                        display: this.userService.fullNameOrUsername
                    }];
                }

                bundleBuilder.add(this.activeCarePlan, HTTPVerb.put, (item) => this.activeCarePlan.meta = item.meta);

                // Activate ProcedureRequests and generate Procedures
                for (let i = 0; i < this.procedureRequests.length; i++) {
                    const pr = this.procedureRequests[i];
                    const duration = pr.duration;

                    pr.procedureRequest.status = fhirEnums.RequestStatus.active;

                    if (this.userService.practitioner) {
                        pr.procedureRequest.performer = {
                            reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                            display: this.userService.fullNameOrUsername
                        };
                    }

                    if (!pr.procedureRequest.occurrenceTiming.event[0]) {
                        const basedOnProcedureRequestReference = pr.procedureRequest?.basedOn.find((basedOn) => basedOn.reference.startsWith(`${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/`));
                        const basedOnProcedureRequest = basedOnProcedureRequestReference ? await this.fhirService.get(basedOnProcedureRequestReference.reference) : null;

                        if (basedOnProcedureRequest) {
                            let prStartDate;

                            if (pr.procedureRequest.occurrenceTiming.code?.coding?.find((coding: any) => coding.system === 'http://nursit-institute.com/fhir/StructureDefinition/timing-datatype')) {
                                prStartDate = this.calculateProcedureRequestStartDateNew(pr, basedOnProcedureRequest, duration, now);
                            } else {
                                prStartDate = this.calculateProcedureRequestStartDate(pr, basedOnProcedureRequest, duration, now);
                            }

                            if (prStartDate) {
                                pr.procedureRequest.occurrenceTiming.event[0] = prStartDate.toJSON();
                                pr.date = prStartDate.toJSON();
                            } else {
                                pr.procedureRequest.occurrenceTiming.event[0] = now.toJSON();
                                pr.date = now.toJSON();
                            }
                        } else {
                            pr.procedureRequest.occurrenceTiming.event[0] = now.toJSON();
                            pr.date = now.toJSON();
                        }
                    }

                    if (pr.procedureRequest.occurrenceTiming.code?.coding?.find((coding: any) => coding.system === 'http://nursit-institute.com/fhir/StructureDefinition/timing-datatype')) {
                        const targetDate = moment(now);

                        if (now.hour() >= 22) {
                            targetDate.add(1, 'day');
                        }

                        this.getProceduresForDate(targetDate, pr).forEach((procedure) => bundleBuilder.add(procedure, HTTPVerb.post));
                    } else {
                        if (duration.type === 'interval') {
                            if (duration.frequency > 0) {
                                this.generatePerPeriod(pr, now).forEach((procedure) => bundleBuilder.add(procedure, HTTPVerb.post));

                                if (now.hour() >= 22) {
                                    this.generatePerPeriod(pr, moment(now).add(1, 'day')).forEach((procedure) => bundleBuilder.add(procedure, HTTPVerb.post));
                                }
                            } else {
                                let endDate = moment(now).endOf('day');

                                if (now.hour() >= 22) {
                                    endDate.add(1, 'day');
                                }

                                this.generateEveryPeriod(pr, endDate, now).forEach((procedure) => bundleBuilder.add(procedure, HTTPVerb.post));
                            }
                        }
                    }

                    bundleBuilder.add(pr.procedureRequest, HTTPVerb.put);
                }

                for (let i = 0; i < this.conditions.length; i++) {
                    this.conditions[i].verificationStatus = 'confirmed';

                    bundleBuilder.add(this.conditions[i], HTTPVerb.put);
                }

                await bundleBuilder.exec();

                this.carePlans = await this.carePlanService.loadCarePlans(this.patient.encounterId);
                this.isLoading = false;
                this.eventAggregator.publish('carePlanOutdatedCheck');
            }
        });
    }

    getProceduresForDate(targetDate: Moment, pr) {
        const weekDaysOptions: Array<{
            value: ("mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun")
        }> = [
                {
                    value: 'mon'
                },
                {
                    value: 'tue'
                },
                {
                    value: 'wed'
                },
                {
                    value: 'thu'
                },
                {
                    value: 'fri'
                },
                {
                    value: 'sat'
                },
                {
                    value: 'sun'
                }
            ];
        const targetDateStart = moment(targetDate).startOf('day');
        const targetDateEnd = moment(targetDate).endOf('day');
        const timing = pr.procedureRequest.occurrenceTiming;

        const monthWeek = timing.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-week')?.valueCodeableConcept?.coding?.map(coding => coding.code);
        const monthDay = timing.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-day')?.valueCodeableConcept?.coding?.map(coding => coding.code);
        const timingData = {
            dateFrom: timing.event[0] ? moment(timing.event[0]) : null,
            dateTo: timing.event[1] ? moment(timing.event[1]) : null,
            period: timing.repeat.periodUnit,
            repeatEvery: timing.repeat.period,
            timeOfDay: !timing.repeat.timeOfDay ? [] : timing.repeat.timeOfDay.map((tod, idx) => ({
                time: {
                    hours: parseInt(tod.split(':')[0], 10),
                    minutes: parseInt(tod.split(':')[1], 10)
                },
                day: timing.repeat.dayOfWeek?.[idx] || null,
                date: monthDay ? new Date(2018, 0, parseInt(monthDay[idx], 10)) : null,
                weekNumber: monthWeek ? parseInt(monthWeek[idx], 10) : null
            })),
        };

        const timings: Moment[] = [];

        if (!timingData.dateFrom) {
            console.warn('No date from set for Procedure Timing Generation');
            return timings;
        }

        let date = moment(timingData.dateFrom);
        // let i = 0

        while ((timingData.dateTo && moment(date).isSameOrBefore(moment(timingData.dateTo).add(1, 'month')) || !timingData.dateTo) && moment(date).isSameOrBefore(moment(targetDateEnd).add(1, 'month'))) {
            if (!['min', 'h'].includes(timingData.period)) {
                for (let j = 0; j < timingData.timeOfDay.length; j++) {
                    const tod = timingData.timeOfDay[j];

                    if (timingData.period === 'd') {
                        date.set({
                            hour: tod.time.hours,
                            minute: tod.time.minutes
                        });
                    } else if (timingData.period === 'wk') {
                        const targetDay = weekDaysOptions.findIndex(wd => wd.value === tod.day);
                        date.hour(tod.time.hours);
                        date.minute(tod.time.minutes);
                        date.isoWeekday(1).add(targetDay, 'days');
                    } else if (timingData.period === 'mo') {
                        if (tod.weekNumber !== null) {
                            let weekCount = 0;
                            const maxDateInMonth = date.clone().endOf('month').date();
                            let _targetDate;

                            for (let d = 1; d <= maxDateInMonth; d++) {
                                const currentDate = date.clone().date(d);
                                if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                                    weekCount++;
                                    if (tod.weekNumber >= 0 && weekCount === tod.weekNumber + 1) {
                                        _targetDate = currentDate;
                                        break;
                                    }
                                }
                            }

                            if (tod.weekNumber === -1 && !_targetDate) {
                                for (let d = maxDateInMonth; d > 0; d--) {
                                    const currentDate = date.clone().date(d);
                                    if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                                        _targetDate = currentDate;
                                        break;
                                    }
                                }
                            }

                            if (!_targetDate) {
                                continue;
                            }

                            date = _targetDate;
                            date.hour(tod.time.hours);
                            date.minute(tod.time.minutes);
                        } else if (tod.date !== null) {
                            const maxDateInMonth = date.clone().endOf('month').date();

                            for (let d = 0; d < maxDateInMonth; d++) {
                                const currentDate = date.clone().date(d);

                                if (currentDate.date() === tod.date.getDate()) {
                                    date = currentDate;
                                    date.hour(tod.time.hours);
                                    date.minute(tod.time.minutes);
                                    break;
                                }
                            }
                        }
                    }

                    if (timingData.dateTo && date.isAfter(moment(timingData.dateTo)) || date.isAfter(moment(targetDateEnd))) {
                        break; // mainLoop;
                    } else if (date.isSameOrAfter(targetDateStart) && date.isSameOrBefore(targetDateEnd)) {
                        timings.push(moment(date));
                    }
                }
            } else {
                if (timingData.dateTo && date.isAfter(moment(timingData.dateTo)) || date.isAfter(moment(targetDateEnd))) {
                    break; // mainLoop;
                } else if (date.isSameOrAfter(targetDateStart) && date.isSameOrBefore(targetDateEnd)) {
                    timings.push(moment(date));
                }
            }

            switch (timingData.period) {
                case 'min':
                    date.minute(date.minute() + timingData.repeatEvery);
                    break;
                case 'h':
                    date.hour(date.hour() + timingData.repeatEvery);
                    break;
                case 'd':
                    date.day(date.day() + timingData.repeatEvery);
                    break;
                case 'wk':
                    date.week(date.week() + timingData.repeatEvery);
                    break;
                case 'mo':
                    date.date(1);
                    date.month(date.month() + timingData.repeatEvery);
                    break;
                default:
                    return timings;
            }

            // i++
        }

        return timings.map((timing) => this.defaultProcedure(pr.procedureRequest, pr.codeSystem, timing));
    }

    generatePerPeriod(pr, targetDay) {
        const procedureRequest = pr.procedureRequest;
        const procedureRequestStart = moment(procedureRequest.occurrenceTiming.event[0]);
        const procedureRequestEnd = procedureRequest.occurrenceTiming.event[1] ? moment(procedureRequest.occurrenceTiming.event[1]) : moment(targetDay).endOf('day');
        const procedures = [];
        const now = moment();
        const weekDays = [
            'sun',
            'mon',
            'tue',
            'wed',
            'thu',
            'fri',
            'sat'
        ];

        if (procedureRequest.occurrenceTiming.repeat && procedureRequest.occurrenceTiming.repeat.timeOfDay) {
            procedureRequest.occurrenceTiming.repeat.timeOfDay.map((tod, idx) => {
                const [hour, minute] = tod.split(':');
                const time = moment(targetDay).startOf('day').add(hour, 'hours').add(minute, 'minutes');
                const todayAfterTen = moment(now).startOf('day').add(22, 'hours');
                const todayBeforeMidnight = moment(now).startOf('day').add(24, 'hours');
                const weekDay = pr.duration.periodUnit === 'wk' && procedureRequest.occurrenceTiming.repeat.dayOfWeek ? procedureRequest.occurrenceTiming.repeat.dayOfWeek[idx] : null;

                if (time.isSameOrAfter(procedureRequestStart) && time.isSameOrBefore(procedureRequestEnd) && (time.isBefore(todayAfterTen) || time.isAfter(todayBeforeMidnight)) && (!weekDay || weekDays[time.day()] == weekDay)) {
                    procedures.push(this.defaultProcedure(procedureRequest, pr.codeSystem, moment(time)));
                }
            });
        } else {
            const scale = this.getScaleForPerPeriod(targetDay, pr.duration);

            if (!scale) {
                return procedures;
            }

            for (let i = 0; i < scale.num; i++) {
                if (scale.step.isSameOrAfter(procedureRequestStart) && scale.step.isSameOrBefore(procedureRequestEnd)) {
                    procedures.push(this.defaultProcedure(procedureRequest, pr.codeSystem, moment(scale.step)));
                }

                scale.step.add(scale.distance, 'minutes');
            }
        }

        return procedures;
    }

    generateEveryPeriod(pr, endDay, now) {
        const procedureRequest = pr.procedureRequest;
        const duration = pr.duration;
        const procedureRequestEnd = procedureRequest.occurrenceTiming.event[1] ? moment(procedureRequest.occurrenceTiming.event[1]) : moment(endDay).endOf('day');
        const endDate = moment(endDay).endOf('day');
        const scale = this.getScaleForEveryPeriod(duration);
        const step = moment(procedureRequest.occurrenceTiming.event[0]);
        const procedures = [];

        while (step.isSameOrBefore(endDate) && step.isSameOrBefore(procedureRequestEnd)) {
            if (step.isSameOrAfter(now)) {
                procedures.push(this.defaultProcedure(procedureRequest, pr.codeSystem, moment(step)));
            }

            step.add(scale, 'minutes');
        }

        return procedures;
    }

    getScaleForPerPeriod(targetDate, duration) {
        let totalLengthHours, step, num, distance;

        switch (duration.periodUnit) {
            case 'd': {
                if (duration.frequency <= 6) {
                    totalLengthHours = 12;
                    step = moment(targetDate).startOf('day').add(7, 'hours');
                } else if (duration.frequency == 7) {
                    totalLengthHours = 14;
                    step = moment(targetDate).startOf('day').add(7, 'hours');
                } else if (duration.frequency == 8) {
                    totalLengthHours = 16;
                    step = moment(targetDate).startOf('day').add(6, 'hours');
                } else if (duration.frequency <= 20) {
                    totalLengthHours = 18;
                    step = moment(targetDate).startOf('day').add(6, 'hours');
                } else {
                    totalLengthHours = 18;
                    step = moment(targetDate).startOf('day').add(3, 'hours');
                }

                num = duration.frequency;
                distance = (totalLengthHours / duration.frequency) * 60;
                break;
            }
            case 'h': {
                totalLengthHours = 24;
                step = moment(targetDate).startOf('day');
                num = totalLengthHours * duration.frequency;
                distance = 60 / duration.frequency;
                break;
            }
            case 'min': {
                return;
            }
        }

        return {
            step,
            num,
            distance
        };
    }

    getScaleForEveryPeriod(duration) {
        switch (duration.periodUnit) {
            case 'd': {
                return 24 * 60 * duration.period;
            }
            case 'h': {
                return 60 * duration.period;
            }
            case 'min': {
                return duration.period;
            }
        }
    }

    calculateProcedureRequestStartDateNew(pr, procedureRequest, duration, now) {
        if (!procedureRequest?.occurrenceTiming?.event?.[0]) {
            return null;
        }

        const procedureRequestStartDate = moment(procedureRequest.occurrenceTiming.event[0]);

        // if basedOnProcedureRequest was created today, ignore this function
        // assume onwards that basedOnProcedureRequest was created at least yesterday
        if (procedureRequestStartDate.isSameOrAfter(now, 'day')) {
            return null;
        }

        const checkTime = moment(procedureRequestStartDate).startOf('day').add(1, 'day');
        let firstProcedureTime = null;

        const weekDaysOptions: Array<{
            value: ("mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun")
        }> = [
                {
                    value: 'mon'
                },
                {
                    value: 'tue'
                },
                {
                    value: 'wed'
                },
                {
                    value: 'thu'
                },
                {
                    value: 'fri'
                },
                {
                    value: 'sat'
                },
                {
                    value: 'sun'
                }
            ];
        const timing = pr.procedureRequest.occurrenceTiming;

        const monthWeek = timing.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-week')?.valueCodeableConcept?.coding?.map(coding => coding.code);
        const monthDay = timing.repeat.extension?.find(ext => ext.url === 'http://hl7.org/fhir/StructureDefinition/timing-month-day')?.valueCodeableConcept?.coding?.map(coding => coding.code);
        const timingData = {
            dateFrom: procedureRequestStartDate,
            dateTo: timing.event[1] ? moment(timing.event[1]) : null,
            period: timing.repeat.periodUnit,
            repeatEvery: timing.repeat.period,
            timeOfDay: !timing.repeat.timeOfDay ? [] : timing.repeat.timeOfDay.map((tod, idx) => ({
                time: {
                    hours: parseInt(tod.split(':')[0], 10),
                    minutes: parseInt(tod.split(':')[1], 10)
                },
                day: timing.repeat.dayOfWeek?.[idx] || null,
                date: monthDay ? new Date(2018, 0, parseInt(monthDay[idx], 10)) : null,
                weekNumber: monthWeek ? parseInt(monthWeek[idx], 10) : null
            })),
        };

        if (!timingData.dateFrom) {
            console.warn('No date from set for Procedure Timing Generation');
            return null;
        }

        let date = moment(timingData.dateFrom);
        let i = 0;

        mainLoop: while ((timingData.dateTo && moment(date).isSameOrBefore(moment(timingData.dateTo).add(1, 'month')) || !timingData.dateTo) && i < 365) {
            if (!['min', 'h'].includes(timingData.period)) {
                for (let j = 0; j < timingData.timeOfDay.length; j++) {
                    const tod = timingData.timeOfDay[j];

                    if (timingData.period === 'd') {
                        date.set({
                            hour: tod.time.hours,
                            minute: tod.time.minutes
                        });
                    } else if (timingData.period === 'wk') {
                        const targetDay = weekDaysOptions.findIndex(wd => wd.value === tod.day);
                        date.hour(tod.time.hours);
                        date.minute(tod.time.minutes);
                        date.isoWeekday(1).add(targetDay, 'days');
                    } else if (timingData.period === 'mo') {
                        if (tod.weekNumber !== null) {
                            let weekCount = 0;
                            const maxDateInMonth = date.clone().endOf('month').date();
                            let _targetDate;

                            for (let d = 1; d <= maxDateInMonth; d++) {
                                const currentDate = date.clone().date(d);
                                if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                                    weekCount++;
                                    if (tod.weekNumber >= 0 && weekCount === tod.weekNumber + 1) {
                                        _targetDate = currentDate;
                                        break;
                                    }
                                }
                            }

                            if (tod.weekNumber === -1 && !_targetDate) {
                                for (let d = maxDateInMonth; d > 0; d--) {
                                    const currentDate = date.clone().date(d);
                                    if (currentDate.isoWeekday() === weekDaysOptions.findIndex(wd => wd.value === tod.day) + 1) {
                                        _targetDate = currentDate;
                                        break;
                                    }
                                }
                            }

                            if (!_targetDate) {
                                continue;
                            }

                            date = _targetDate;
                            date.hour(tod.time.hours);
                            date.minute(tod.time.minutes);
                        } else if (tod.date !== null) {
                            const maxDateInMonth = date.clone().endOf('month').date();

                            for (let d = 0; d < maxDateInMonth; d++) {
                                const currentDate = date.clone().date(d);

                                if (currentDate.date() === tod.date.getDate()) {
                                    date = currentDate;
                                    date.hour(tod.time.hours);
                                    date.minute(tod.time.minutes);
                                    break;
                                }
                            }
                        }
                    }

                    if (timingData.dateTo && date.isAfter(moment(timingData.dateTo))) {
                        break mainLoop;
                    } else if (date.isSameOrAfter(checkTime)) {
                        firstProcedureTime = moment(date);
                        break mainLoop;
                    }
                }
            } else {
                if (timingData.dateTo && date.isAfter(moment(timingData.dateTo))) {
                    break; // mainLoop;
                } else if (date.isSameOrAfter(checkTime)) {
                    firstProcedureTime = moment(date);
                    break; // mainLoop;
                }
            }

            switch (timingData.period) {
                case 'min':
                    date.minute(date.minute() + timingData.repeatEvery);
                    break;
                case 'h':
                    date.hour(date.hour() + timingData.repeatEvery);
                    break;
                case 'd':
                    date.day(date.day() + timingData.repeatEvery);
                    break;
                case 'wk':
                    date.week(date.week() + timingData.repeatEvery);
                    break;
                case 'mo':
                    date.date(1);
                    date.month(date.month() + timingData.repeatEvery);
                    break;
                default:
                    console.warn('Unrecognized period: ' + timingData.period);
                    return null;
            }

            i++;
        }

        const procedureTime = firstProcedureTime ? firstProcedureTime.startOf('day') : null;

        if (procedureTime.isBefore(now)) {
            return null;
        }

        return procedureTime;
    }

    calculateProcedureRequestStartDate(pr, procedureRequest, duration, now) {
        if (!procedureRequest?.occurrenceTiming?.event?.[0]) {
            return null;
        }

        const procedureRequestStartDate = moment(procedureRequest.occurrenceTiming.event[0]);

        // if basedOnProcedureRequest was created today, ignore this function
        // assume onwards that basedOnProcedureRequest was created at least yesterday
        if (procedureRequestStartDate.isSameOrAfter(now, 'day')) {
            return null;
        }

        let firstProcedureTime = null;

        if (duration.type === 'interval') {
            if (duration.frequency > 0) {
                const checkTime = moment(procedureRequestStartDate).startOf('day').add(1, 'day');
                let i = 0;

                while (!firstProcedureTime && i < 365) {
                    const weekDays = [
                        'sun',
                        'mon',
                        'tue',
                        'wed',
                        'thu',
                        'fri',
                        'sat'
                    ];

                    if (pr.procedureRequest.occurrenceTiming.repeat && pr.procedureRequest.occurrenceTiming.repeat.timeOfDay) {
                        pr.procedureRequest.occurrenceTiming.repeat.timeOfDay.map((tod, idx) => {
                            const weekDay = pr.duration.periodUnit === 'wk' && pr.procedureRequest.occurrenceTiming.repeat.dayOfWeek ? pr.procedureRequest.occurrenceTiming.repeat.dayOfWeek[idx] : null;

                            if (!weekDay || weekDays[checkTime.day()] == weekDay) {
                                firstProcedureTime = moment(checkTime);
                            }
                        });
                    } else {
                        const scale = this.getScaleForPerPeriod(checkTime, pr.duration);

                        if (!scale) {
                            return null;
                        }

                        for (let i = 0; i < scale.num; i++) {
                            if (scale.step.isSameOrAfter(checkTime)) {
                                firstProcedureTime = moment(checkTime);
                                break;
                            }

                            scale.step.add(scale.distance, 'minutes');
                        }
                    }

                    checkTime.add(1, 'day');
                    i++;
                }
            } else {
                const checkTime = moment(procedureRequestStartDate).startOf('day').add(1, 'day');
                const scale = this.getScaleForEveryPeriod(pr.duration);
                const step = moment(procedureRequestStartDate);
                let i = 0;

                while (!firstProcedureTime && i < 365) {
                    if (step.isSameOrAfter(checkTime)) {
                        firstProcedureTime = moment(step);
                        break;
                    }

                    step.add(scale, 'minutes');
                    i++;
                }
            }
        } else {
            return null;
        }

        const procedureTime = firstProcedureTime ? firstProcedureTime.startOf('day') : null;

        if (procedureTime.isBefore(now)) {
            return null;
        }

        return procedureTime;
    }

    defaultProcedure(procedureRequest, codeData, date) {
        const periodStart = date;
        const periodEnd = moment(date).add(procedureRequest.occurrenceTiming.repeat.duration, 'minutes');

        return {
            id: 'urn:uuid:' + NitTools.Uid(),
            resourceType: 'Procedure',
            basedOn: [
                { reference: `CarePlan/${this.activeCarePlan.id}` },
                { reference: `${FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest}/${procedureRequest.id}` }],
            subject: this.activeCarePlan.subject,
            [FhirService.FhirVersion > 3 ? 'encounter' : 'context']: this.activeCarePlan[FhirService.FhirVersion > 3 ? 'encounter' : 'context'],
            status: 'preparation',
            category: procedureRequest.category ? procedureRequest.category[0] : undefined,
            code: {
                coding: [{
                    system: CarePlanService.getGmtCodeSystemUri(this.patient),
                    code: codeData.code
                }]
            },
            performedPeriod: {
                start: periodStart.toJSON(),
                end: periodEnd.toJSON()
            }
        };
    }

    saveAsTemplate() {
        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.TEMPLATES.SAVE)) {
            this.isLoading = false;
            return;
        }

        this.dialogService.open({
            viewModel: PromptInput,
            model: {
                message: this.i18n.tr("enter_template_name"),
                title: translations.translate('save_as_template'),
                yesText: translations.translate("ok"),
                noText: translations.translate('abort')
            },
            lock: true
        }).whenClosed(async (dialogResult) => {
            if (!dialogResult.wasCancelled && dialogResult.output) {
                this.isLoading = true;

                const listProcedureRequests = [];
                const listName = dialogResult.output;
                const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

                for (let i = 0; i < this.eligibleProcedureRequests.length; i++) {
                    const procedureRequest = this.eligibleProcedureRequests[i].procedureRequest;
                    const mappedProcedureRequest: any = {
                        id: 'urn:uuid:' + NitTools.Uid(),
                        resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
                        asNeededBoolean: procedureRequest.asNeededBoolean,
                        status: RequestStatus.draft,
                        intent: RequestIntent.plan,
                        code: procedureRequest.code,
                        occurrenceTiming: {}
                    };

                    if (procedureRequest.occurrenceTiming.code) {
                        mappedProcedureRequest.occurrenceTiming.code = procedureRequest.occurrenceTiming.code;
                    }

                    if (procedureRequest.occurrenceTiming.repeat) {
                        mappedProcedureRequest.occurrenceTiming.repeat = procedureRequest.occurrenceTiming.repeat;
                    }

                    listProcedureRequests.push(mappedProcedureRequest.id);

                    bundleBuilder.add(mappedProcedureRequest, HTTPVerb.post);
                }

                const list = {
                    id: 'urn:uuid:' + NitTools.Uid(),
                    resourceType: 'List',
                    status: 'current',
                    mode: 'snapshot',
                    title: listName,
                    code: {
                        coding: [{
                            system: this.listURI,
                            code: this.listCode
                        }]
                    },
                    date: moment().toJSON(),
                    entry: listProcedureRequests.map((procedureId) => {
                        return {
                            item: { reference: procedureId }
                        };
                    })
                };

                const existingList = this.templatesList.find((l) => l.title === listName);

                if (existingList) {
                    bundleBuilder.add({
                        id: existingList.id,
                        resourceType: 'List',
                        status: existingList.status,
                        mode: existingList.mode,
                        title: existingList.title,
                        code: existingList.code,
                        date: list.date,
                        entry: list.entry
                    }, HTTPVerb.put, (item) => {
                        existingList.date = item.date;
                        existingList.entry = item.entry;
                    });

                    for (let j = 0; j < existingList.entry.length; j++) {
                        const id = existingList.entry[j].item.reference.split('/')[1];

                        bundleBuilder.add({
                            resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
                            id: id
                        }, HTTPVerb.delete);
                    }

                    existingList.date = list.date;
                    existingList.entry = list.entry;
                } else {
                    bundleBuilder.add(list, HTTPVerb.post, (item) => this.templatesList.push(item));
                }

                await bundleBuilder.exec();

                this.isLoading = false;
            }
        });
    }

    async selectTemplate(template) {
        if (this.isCarePlanReadOnly) return;

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.TEMPLATES.USE)) {
            this.isLoading = false;
            return;
        }

        this.isLoading = true;

        this.dialogMessages.dialog(this.i18n.tr('ask_planning_template_action'), '', this.i18n.tr("replace_planning"), this.i18n.tr("add_planning"), true).whenClosed(async (result) => {
            const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);
            const isReplacing = !result.wasCancelled;
            const oldProcedureRequests = this.procedureRequests.slice();

            if (isReplacing) {
                this.procedureRequests.forEach((pr) => {
                    pr.isDeleted = true;
                });
            }

            for (let i = 0; i < template.entry?.length; i++) {
                const id = template.entry[i].item.reference.split('/')[1];

                bundleBuilder.add({
                    resourceType: (FhirService.FhirVersion > 3 ? ResourceType.serviceRequest : ResourceType.procedureRequest),
                    id: id
                }, HTTPVerb.get, (procedureRequest) => {
                    const code = procedureRequest.code.coding[0].code;
                    const codeData = CarePlanService.findCode(code, this.codeSystem);

                    if (!codeData) {
                        console.warn(`Code ${code} not found in code system ${this.codeSystem.name}`);
                        return;
                    }

                    const parsedCodeSystem = CarePlanService.parseCodeSystem(codeData);
                    const parsedDuration = CarePlanService.parseCodeSystemDuration(procedureRequest.occurrenceTiming);

                    delete procedureRequest.meta;
                    procedureRequest.id = NitTools.Uid();
                    procedureRequest.basedOn = [{ reference: "CarePlan/" + this.activeCarePlan.id }];
                    procedureRequest.subject = { reference: `${ResourceType.patient}/${this.patient.id}` };
                    procedureRequest[FhirService.FhirVersion > 3 ? 'encounter' : 'context'] = { reference: `${ResourceType.encounter}/${this.patient.encounterId}` };
                    procedureRequest.occurrenceTiming.event = [];

                    if (this.userService.practitioner) {
                        procedureRequest.performer = {
                            reference: `${ResourceType.practitioner}/${this.userService.practitioner.id}`,
                            display: this.userService.fullNameOrUsername
                        };
                    }

                    const isExisting = !oldProcedureRequests.find((pr) => pr.codeSystem.code === parsedCodeSystem.code && !pr.isDeleted);

                    if (isReplacing || isExisting) {
                        this.procedureRequests.push({
                            procedureRequest,
                            codeSystem: parsedCodeSystem,
                            duration: parsedDuration,
                            description: this.procedureDescription(procedureRequest, parsedCodeSystem, parsedDuration),
                            groupId: this.findProcedureRequestGroupId(procedureRequest),
                            isInGroup: this.isProcedureRequestInActiveMapping(procedureRequest),
                            isControlled: this.isProcedureRequestControlled(procedureRequest),
                            date: procedureRequest.occurrenceTiming.event[0],
                            isAdded: true,
                            isDeleted: false,
                            isUpdated: false
                        });
                    }
                });
            }

            await bundleBuilder.exec();

            this.sortProcedureRequests();
            this.countActiveMappingItemTasks();
            this.isLoading = false;
        }, (e) => {
            this.isLoading = false;
        });
    }

    deleteTemplate(e, template) {
        e.stopPropagation();

        this.dialogMessages.dialog(this.i18n.tr('confirmDeleteTemplate', { template: template.title }), this.i18n.tr("confirm"), this.i18n.tr("yes"), this.i18n.tr("no"))
            .whenClosed(async (result) => {
                if (!result.wasCancelled) {
                    this.isLoading = true;
                    await this.fhirService.delete(template);

                    const existingListId = this.templatesList.findIndex((l) => l.id === template.id);
                    if (existingListId > -1) {
                        this.templatesList.splice(existingListId, 1);
                    }

                    this.isLoading = false;
                }
            });
    }

    openAllDiagnoses() {
        if (this.allDiagnosesDisabled) {
            return;
        }

        if (!this.permissionService.canAlert(PermissionService.FEATURES.CAREIT.PLANNING.DIAGNOSIS)) {
            this.isLoading = false;
            return;
        }

        this.dialogService.open({
            viewModel: ModalDiagnosis,
            model: {
                patient: this.patient,
                conditions: this.conditions
            },
            lock: true
        }).whenClosed(async (result) => {
            if (!result.wasCancelled) {
                this.isLoading = true;

                const checkOutdated = await this.checkIsCarePlanOutdated();

                if (checkOutdated.outdated) {
                    this.dialogMessages.prompt(this.i18n.tr('planning_outdated')).whenClosed(async () => {
                        this.carePlans = checkOutdated.carePlans;
                        this.activeCarePlan = checkOutdated.carePlan;
                        this.isLoading = false;
                    });
                    return;
                }

                const bundleBuilder = this.fhirService.bundleBuilder(BundleType.transaction);

                for (let i = 0; i < this.conditions.length; i++) {
                    bundleBuilder.add(this.conditions[i], HTTPVerb.delete);
                }

                this.conditions = [];
                this.activeCarePlan.addresses = [];

                result.output.forEach((diagnose) => {
                    const condition = this.createCondition(diagnose.code, diagnose.definition || diagnose.display);

                    this.activeCarePlan.addresses.push({ reference: `Condition/${condition.id}` });

                    bundleBuilder.add(condition, HTTPVerb.post, (item) => {
                        this.conditions.push(item);
                    });
                });

                await this.saveCarePlan(bundleBuilder);

                this.generateDiagnosisQuestionnaireItemsFromCurrentMapping();

                this.procedureRequests.forEach((pr) => {
                    pr.isInGroup = this.isProcedureRequestInActiveMapping(pr.procedureRequest);
                    pr.isControlled = this.isProcedureRequestControlled(pr.procedureRequest);
                });

                this.countActiveMappingItemTasks();
            }
        });

    }

    removeProceduresWithoutDiagnosis() {
        this.eligibleProcedureRequestsNotInGroup.forEach((pr) => {
            pr.isDeleted = true;

            this.taskItems.forEach((task) => {
                const taskProcedureRequest = this.procedureRequests.find((pr) => !pr.isDeleted && pr.codeSystem.code === task.code);

                task.hasActiveTask = Boolean(taskProcedureRequest);
                task.isControlled = taskProcedureRequest ? this.isProcedureRequestControlled(taskProcedureRequest.procedureRequest) : false;
            });
        });
    }

    async checkIsCarePlanOutdated() {
        const carePlans = await this.carePlanService.loadCarePlans(this.patient.encounterId);
        const sameCarePlan = carePlans.find((c) => c.id === this.activeCarePlan.id);
        const procedureRequests = await this.loadProcedureRequests();
        let outdatedProcedureRequests = false;

        procedureRequests.forEach((pr) => {
            const npr = this.procedureRequests.find((p) => p.procedureRequest.id === pr.procedureRequest.id);

            if (!npr || pr.procedureRequest.meta.versionId !== npr.procedureRequest.meta.versionId) {
                outdatedProcedureRequests = true;
            }
        });

        return {
            outdated: sameCarePlan.meta.versionId !== this.activeCarePlan.meta.versionId || outdatedProcedureRequests,
            carePlan: sameCarePlan,
            carePlans
        };
    }
}
