import {FormBaseClass} from "../../../resources/elements/FormBaseClass";
import {translations} from "../../../resources/classes/translations";
import * as Fhir from "../../../resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, Questionnaire, QuestionnaireResponse} from "../../../resources/classes/FhirModules/Fhir";
import {ConfigService} from "../../../resources/services/ConfigService";
import {IQuestionnaireList, QuestionnaireService} from "resources/services/QuestionnaireService";
import {PatientService} from "resources/services/PatientService";
import {fhirEnums} from "../../../resources/classes/fhir-enums";
import {PKMSService} from "../../../resources/services/PKMSService";
import {DialogMessages} from "../../../resources/services/DialogMessages";
import {AnalyzeService} from "../../../resources/services/analyzeService";
import {RuntimeInfo} from "../../../resources/classes/RuntimeInfo";
import {PatientItem} from "../../../resources/classes/Patient/PatientItem";
import {FhirService} from "../../../resources/services/FhirService";
import {BasicForm} from "../../../resources/elements/BasicForm";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;

const moment = require("moment");

export class viewAssessment extends FormBaseClass {
    cnt = 0;
    oldShowPkms: boolean;

    async patientUpdated() {
        /* await super.patientUpdated();
        await this.getSpi(); */
    }

    async attached() {
        this.showTrashcan = false;
        this.route = ConfigService.FormNames.Assessment;
        await super.attached();
        this.buttonText = translations.translate("new_assessment");

        if (ConfigService.Debug)
            window["assessment"] = this;
    }

    async patientChanged(patient: PatientItem) {
        await super.patientChanged(patient);
    }

    async createButtonClicked(preset?): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
                if (this.patient?.isOffline) reject('Patient is offline, editing not possible');
                let abort = false;
                if ((!RuntimeInfo.Features.assessmentNeedsToHaveCompletedAnamnesis === false) // may not be specified, so this is true unless explicitely set to false
                    && this.patient && this.patient.questionnaireResponses) {
                    await ConfigService.LoadConfigOverride(this.patient?.ward, this.patient);
                    let anamnesisQuestionnaire = this.questionnaireService.getQuestionnaireByNameDirect(this.patient.getAnamnesisName());

                    let lastAnamnesis = QuestionnaireService.GetLatestResponseOfType(this.patient, anamnesisQuestionnaire.id);
                    if (!lastAnamnesis) {
                        this.dialogMessages.prompt(this.i18n.tr('anamnesis_needed_for_assessment'), this.i18n.tr('information'), false);
                        abort = true;
                    } else if (['completed', 'amended'].indexOf(lastAnamnesis.status) === -1) {
                        this.dialogMessages.prompt(this.i18n.tr('completed_anamnesis_needed_for_assessment'), this.i18n.tr('information'), false);
                        abort = true;
                    }
                }

                if (!abort)
                    resolve(await super.createButtonClicked(preset));
                else
                    reject('Aborted');
            }
        );
    }

    override async afterResponseChanged(response: any) : Promise<void> {
        window.setTimeout(() => this.isLoading = false, 5000);
        await super.afterResponseChanged(response);
    }

    async afterLoadedPatient(patient) {
        await super.afterLoadedPatient(patient);

        try {
            if (this.patient) {
                this.questionnaireName = this.patient.getAssessmentName();
                this.questionnaire = await this.questionnaireService.getQuestionnaireByName(this.questionnaireName);
            }
        } catch (error) {
            let msg = error.message || JSON.stringify(error);
            this.dialogMessages.prompt(msg, this.i18n.tr('warning'), true);
            console.warn(msg);
            this.questionnaireName = 'CareITAssessment';
            this.questionnaire = await this.questionnaireService.getQuestionnaireByName(this.questionnaireName);
        }

    }

    calculateFim() {
        // TODO: weiter aus Delphi übernehmen
        /*************************************************************
         //#region create assessmentToFIMDictionary values
         let assessmentToFIMDictionary: KeyValuePair[] = [
         new KeyValuePair('03_01', 'FIM_01_01'),
         new KeyValuePair('05_01', 'FIM_01_02'),
         new KeyValuePair('05_02', 'FIM_01_03'),
         new KeyValuePair('05_03', 'FIM_01_04'),
         new KeyValuePair('05_04', 'FIM_01_05'),
         new KeyValuePair('04_03', 'FIM_01_06'),
         new KeyValuePair('04_02', 'FIM_02_01'),
         new KeyValuePair('04_04', 'FIM_02_02'),
         new KeyValuePair('01_02', 'FIM_03_01'),
         new KeyValuePair('01_03', 'FIM_03_02'),
         new KeyValuePair('01_09', 'FIM_03_03'),
         new KeyValuePair('01_06', 'FIM_04_01'),
         new KeyValuePair('01_01', 'FIM_04_02'),
         new KeyValuePair('06_03', 'FIM_05_01'),
         new KeyValuePair('07_09', 'FIM_05_02'),
         new KeyValuePair('07_10', 'FIM_06_01'),
         new KeyValuePair('06_05', 'FIM_06_02'),
         new KeyValuePair('06_06', 'FIM_06_03')
         ];
         //#endregion

         //#region create assessmentFieldToValues values
         let assessmentFieldToValues: KeyValuePair[] = [
         new KeyValuePair('01_01', '1-2-3-4'),
         new KeyValuePair('01_02', '1-2-3-4'),
         new KeyValuePair('01_03', '1-2-3'),
         new KeyValuePair('01_06', '1-4'),
         new KeyValuePair('01_07', '1-4'),
         new KeyValuePair('01_09', '1-2-3-4'),
         new KeyValuePair('05_01', '1-2-3-4'),
         new KeyValuePair('05_02', '1-2-3-4'),
         new KeyValuePair('05_03', '1-2-3-4'),
         new KeyValuePair('05_04', '1-2-3-4'),
         new KeyValuePair('03_01', '1-2-3-4'),
         new KeyValuePair('03_05', '1-2-3-4'),
         new KeyValuePair('03_18', '1-4'),
         new KeyValuePair('03_10', '1-4'),
         new KeyValuePair('04_01', '1-2-3-4'),
         new KeyValuePair('04_02', '1-2-3-4'),
         new KeyValuePair('04_03', '1-2-3-4'),
         new KeyValuePair('04_04', '1-2-3-4'),
         new KeyValuePair('04_09', '1-4'),
         new KeyValuePair('04_10', '1-4'),
         new KeyValuePair('06_01', '1-2-3-4'),
         new KeyValuePair('06_02', '1-2-3-4'),
         new KeyValuePair('06_03', '1-2-3-4'),
         new KeyValuePair('06_05', '1-2-3-4'),
         new KeyValuePair('06_06', '1-4'),
         new KeyValuePair('07_07', '1-2-4'),
         new KeyValuePair('07_08', '1-2-4'),
         new KeyValuePair('07_09', '1-2-4'),
         new KeyValuePair('07_10', '1-2-3-4'),
         new KeyValuePair('07_06', '1 (11, 12, 13, 14) -4')
         ];
         //#endregion

         let motorSum = 0;
         let cognitiveSum = 0;

         assessmentToFIMDictionary.forEach((kvp: KeyValuePair) => {
            let fimKey = kvp.value;
            let FimValue = this.GetKeyValueAsInteger(fimKey);
            if ((fimKey.indexOf('FIM_06') === 0) || (fimKey.indexOf('FIM_05') === 0)) {
                cognitiveSum += FimValue;
            } else {
                motorSum += FimValue;
            }
        });
         *************************************************************/
        // weiter in Delphi, FrameAssessment.pas, line 1846 ff
    }

    afterCreate(result: any) {
        this.selectedid = result.id;
        super.afterCreate(result);

        /*let mark1 = false;
        if (this.patient && this.patient.flags && this.patient.flags.code && this.patient.flags.code.coding && this.patient.flags.code.coding.length > 0) {
            let flag1 = this.patient.flags.code.coding.find(o => o.system.endsWith('mark_1'));
            if (flag1)
                mark1 = NitTools.ParseBool(flag1.code);
        } */

        let isolationItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, '00_90_01_11');
        if (isolationItem) {
            isolationItem.answer = [
                {
                    valueCoding: {
                        code: this.patient.mark_1 ? '00_90_01_11_02' : '00_90_01_11_01',
                        display: this.patient.mark_1 ? this.i18n.tr('yes') : this.i18n.tr('no')
                    }
                }
            ];
        }

        this.createAttachedQuestionnaires(this.response, result)
            .then(() => this.isLoading = false);

        // let anamnesis = QuestionnaireService.Get LatestQuestionnaireOfType(this.patient, this.qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
        // CIRiskAssessment.annulateExistingRiskAssessments(this.patient, this.fhirService, result, anamnesis)
        //     .catch(e => {
        //         console.warn(e);
        //     })
        //     .finally(async () => {
        //         await this.createAttachedQuestionnaires(this.response);
        //         this.isLoading = false;
        //     });
    }

    async beforeSaveNewItem(newItem: any) {
        this.isLoading = true;

        await super.beforeSaveNewItem(newItem);
        await this.invalidateOldAttachedQuestionnaires();

        /* // remove Analyzer Version so that new one is used if existent
        if (newItem.extension) {
            let existing = newItem.extension.find(o=>o.url && o.url.toUpperCase().endsWith('ANALYZERVERSION'));
            if (existing) newItem.extension.splice(newItem.extension.indexOf(existing), 1);
        } */

        /*let cfg = ConfigService.GetFormSettings('assessment');
        if (cfg && cfg["analyzer"]) {
            AnalyzeService.UpdateAnalyzerExtension(this.patient, newItem, cfg["analyzer"]);
        }*/

        this.notifier.notify(this.patient);
    }

    async detached() {
        if (this.response && !this.readonly) {
            // this.beforeSave();
        }

        await super.detached();
    }

    init() {
        this.isLoading = true;
        super.init();
        this.noPatientProblemsText = this.i18n.tr("no_patient_problems_assessment");
    }

    formChanged(sender) {
        super.formChanged(sender);
        this.recalcRisks(this.response, false).catch(error => console.warn(error));
        this.getSpi().catch(err => console.warn(err));
    }

    async recalcRisks(assessment: any, saveToServer: boolean) {
        this.oldShowPkms = this.showPkms;
        if (!saveToServer) {
            this.taskQueue.queueMicroTask(async () => {
                let spiResult = await this.analyzeService.calculateSpi(this.patient, assessment);
                if (typeof spiResult.sum !== "undefined") {
                    if (!this.hasChanges)
                        await PatientItem.UpdateCareLevel(this.patient, spiResult.careLevel);

                    this.patient.pkms_relevant = AnalyzeService.PkmsRelevanceStart > 0 ? spiResult.sum <= AnalyzeService.PkmsRelevanceStart : false;
                    this.showPkms = this.patient.pkms_relevant;
                    this.pkmsSum = spiResult.sum;

                    if (this.showPkms && this.showPkms !== this.oldShowPkms) {
                        RuntimeInfo.ShowInfo(this.i18n.tr("pkms_addition_should_be_documented"), true);
                    }
                }
            });
        }
    }

    updatePKMSDiagnosisItem() {
        let reasons = PKMSService.GetPKMSReasonsList(this.patient, this.qList);
        let groups: any[] = [];

        // first get the distinct groups
        reasons.forEach(reason => {
            let exitsing = groups.find(o => o.groupName === reason.groupName);
            if (!exitsing) {
                groups.push({groupName: reason.groupName, reasonNames: [], display: ""});
            }
        });

        // then fill the reasonNames for each group
        groups.forEach(group => {
            reasons.filter(o => o.groupName === group.groupName).forEach(reason => {
                let reasonShort = reason.reasonName;
                if (reasonShort.indexOf('.') > -1) {
                    reasonShort = reasonShort.split('.')[0];
                }

                if (group.reasonNames.indexOf(reasonShort) === -1) {
                    group.reasonNames.push(reasonShort);
                }
            });

            group.display = `${group.groupName}: ${group.reasonNames.join(', ')}`;
        });


        let item: any = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, "PKMS_Diagnosis", true);
        if (!item) {
            item = {
                linkId: 'PKMS_Diagnosis',
            };

            this.response.item.push(item);
        }

        if (item) {
            item.answer = [];

            groups.forEach(group => {
                item.answer.push({
                    valueCoding: {
                        code: group.display,
                        display: group.display
                    }
                });
            });
        }
    }

    async checkAttachedResponse(linkId: string, questionnaireId: string): Promise<void> {
        return new Promise(async (resolve, reject) => {
            let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, linkId, false);
            if (!responseItem) {
                try {
                    responseItem = QuestionnaireResponse.GetResponseItemByLinkId(this.response, linkId, true);
                    if (responseItem) {
                        let questionnaire = QuestionnaireService.GetQuestionnaireDirect(questionnaireId);
                        let questionnaireResponse = Fhir.Tools.SubstituteDefaultQRSkeleton(this.patient, questionnaireId);
                        questionnaireResponse = <any>await this.fhirService.create(questionnaireResponse);

                        responseItem.answer = [
                            {
                                valueReference: {
                                    reference: `QuestionnaireResponse/${questionnaireResponse.id}`,
                                    display: questionnaire.title
                                }
                            }
                        ];

                        this.patientService.addQuestionnaireResponse(this.patient, questionnaireResponse, true);
                    }

                    resolve();
                } catch (e) {
                    let msg = DialogMessages.HttpErrorToString(e);
                    console.warn(msg);
                    reject(msg);
                }
            } else {
                resolve();
            }
        });
    }

    async beforeSave() {
        this.isLoading = true;

        await super.beforeSave();

        try {
            Fhir.Questionnaire.EnsureStructuredResponse(this.questionnaire, this.response);

            // ensure the existence of Barthel, BiEx and FIM:
            await this.checkAttachedResponse('BarthelIndex', this.qList.QBarthelIndexId);
            await this.checkAttachedResponse('BarthelIndexEx', this.qList.QBarthelIndexExId);
            await this.checkAttachedResponse('FIM', this.qList.QFimId);

            // the current response could be a clone, so play save and when it's the latest assessment (which should be used in the analyse)
            // take the answers from patient.latestAssessment, because they should be always right
            if (this.response && this.patient && this.patient.latestAssessment
                && (this.patient.latestAssessment.status === fhirEnums.QuestionnaireResponseStatus.completed || this.patient.latestAssessment.status === fhirEnums.QuestionnaireResponseStatus.amended)
                && this.response.id === this.patient.latestAssessment.id) {
                this.updatePKMSDiagnosisItem();
                // already done in FormBaseClass.headerSaveButtonClicked() : await this.analyzeService.analyse(this.patient, this.response, true, true);
            }
        } catch (e) {
            if (ConfigService.Debug) {
                this.isLoading = false;
                throw (e);
            }

            console.warn("[view-assessment] - beforeSave() has failed", e.message || e);
        }
    }

    invalidateOldAttachedQuestionnaires() {
        // this is not valid anymore!!!
        return Promise.resolve();
    }

    public static SetResponseLinkItem(target: any, linkTarget: any, name: string) {
        let item = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(target, name, true);
        if (!item) {
            return false;
        }

        item.answer = [
            {
                valueReference: {
                    reference: `${linkTarget.resourceType}/${linkTarget.id}`,
                    display: name
                }
            }
        ];

        return true;
    }

    async createAttachedQuestionnaires(forQuestionnaire: any, assessment: any): Promise<any> {
        return viewAssessment.CreateAttachedQuestionnaires(forQuestionnaire, this.patient, this.qList, this.fhirService, assessment);
    }

    public static CreateAttachedQuestionnaires(forQuestionnaire: any, patient: PatientItem, qList: IQuestionnaireList, fhirService: FhirService, assessment: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            await ConfigService.LoadConfigOverride(patient?.ward, patient);
            const fs = ConfigService.GetFormSettings('assessment');
            let create = true;
            if (fs.autoGenerateNewResponses && fs.autoGenerateNewResponses.length > 0) {
                if (ConfigService.Debug)
                    console.info('Skipping creating attached questionnaires in assessment, because an autogenerateNewResponses array has been specified in ' + ConfigService.ConfigName);

                create = false;
            }

            if (create) {
                QuestionnaireService.GetQuestionnaireIds()
                    .then(async (list: IQuestionnaireList) => {

                        let anamnesis = QuestionnaireService.GetLatestResponseOfType(patient, qList.QAnamnesisId, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                        await CIRiskAssessment.annulateExistingRiskAssessments(patient, fhirService, assessment, anamnesis);
                        let bi = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, list.QBarthelIndexId, fhirEnums.QuestionnaireResponseStatus.inProgress);

                        let biEx = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, list.QBarthelIndexExId, fhirEnums.QuestionnaireResponseStatus.inProgress);
                        let fim = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, list.QFimId, fhirEnums.QuestionnaireResponseStatus.inProgress);
                        let diag = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, list.QDiagnosisId, fhirEnums.QuestionnaireResponseStatus.inProgress);

                        fhirService.bundle([bi, biEx, fim, diag], HTTPVerb.post, BundleType.transaction)
                            .then(async (result: any) => {
                                result.entry.forEach(bundleEntry => {
                                    PatientService.AddQuestionnaireResponse(patient, <any>bundleEntry.resource);
                                });

                                const responses = <any[]>result.entry?.filter(o => o.resource?.resourceType === "QuestionnaireResponse").map(r => r.resource);
                                if (FhirService.FhirVersion > 3) {
                                    bi = responses.find(o => String(o.questionnaire).toUpperCase().indexOf(PatientItem.GetQuestionnaireName(patient, 'barthelindex', 'CareITBarthelIndexSempa').toUpperCase()) > -1);
                                    biEx = responses.find(o => String(o.questionnaire).toUpperCase().indexOf(PatientItem.GetQuestionnaireName(patient, 'barthelindexEx', 'CareITExBarthelIndexSempa').toUpperCase()) > -1);
                                    fim = responses.find(o => String(o.questionnaire).toUpperCase().indexOf(PatientItem.GetQuestionnaireName(patient, 'fim', 'CareITFIM').toUpperCase()) > -1);
                                    diag = responses.find(o => String(o.questionnaire).toUpperCase().indexOf('CareITDiagnosis') > -1);
                                } else {
                                    bi = responses.find(o => o.questionnaire.reference.indexOf('/' + qList.QBarthelIndexId) > -1);
                                    biEx = responses.find(o => o.questionnaire.reference.indexOf('/' + qList.QBarthelIndexExId) > -1);
                                    fim = responses.find(o => o.questionnaire.reference.indexOf('/' + qList.QFimId) > -1);
                                    diag = responses.find(o => o.questionnaire.reference.indexOf('/' + qList.QDiagnosisId) > -1);
                                }

                                if (this.SetResponseLinkItem(forQuestionnaire, bi, "BarthelIndex") ||
                                    this.SetResponseLinkItem(forQuestionnaire, biEx, "BarthelIndexEx") ||
                                    this.SetResponseLinkItem(forQuestionnaire, fim, "FIM") ||
                                    this.SetResponseLinkItem(forQuestionnaire, diag, "Diagnosis")) {
                                    forQuestionnaire = <any>await fhirService.update(forQuestionnaire);
                                }

                                resolve(forQuestionnaire);
                            })
                            .catch(err => {
                                reject(err);
                            });
                    });
            }
        });
    }

    async afterSave(response: any) {
        try {
            await super.afterSave(response);

            PatientService.AddQuestionnaireResponse(this.patient, response, true);
            const assessment = QuestionnaireService.GetQuestionnaireByNameDirect(this.patient.getAssessmentName());
            let latestAssessment = QuestionnaireService.GetLatestResponseOfType(this.patient, assessment.id, [fhirEnums.QuestionnaireResponseStatus.amended, fhirEnums.QuestionnaireResponseStatus.completed]);

            if (!this.patient.latestAssessment && latestAssessment) {
                this.patient.latestAssessment = latestAssessment;
            }

            let qrs = QuestionnaireService.GetResponsesOfType(this.patient, assessment?.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            qrs.sort((a, b) => {
                return new Date(b.authored).valueOf() - new Date(a.authored).valueOf();
            });

            let latest = QuestionnaireService.GetLatestResponseOfType(this.patient, assessment?.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
            if (latest) {
                this.patient.latestAssessment = latest;
                if (ConfigService.UseAssessmentForSignal10) {
                    let age = moment(new Date()).diff(latest.authored, 'h');
                    if (this.setting && this.setting.expiration && this.setting.expiration.durations) {
                        const mark10 = this.patient.marks.find(o => o.index === 10);
                        mark10.red = age > this.setting.expiration.durations.redAfter;
                        mark10.yellow = age > this.setting.expiration.durations.yellowAfter;
                    }
                }

                this.notifier.notify(this.patient, latest);

                if ((!RuntimeInfo.Features.automaticallyJumpFromCompletedAssessmentToAnalysis === false) // may not be specified, so this is true unless explicitely set to false
                    && latest && latest.id === this.response.id) {
                    RuntimeInfo.IsLoading = false;
                    this.router.navigate('analysis');
                }
            }
        } catch (e) {
            console.warn(e);
        } finally {
            this.isLoading = false;
        }
    }

    isAnalyzing: boolean;

    waitForAnalyze(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            if (!this.isAnalyzing) {
                resolve();
            } else {
                window.setTimeout(async () => {
                    await this.waitForAnalyze();
                    resolve();
                }, 100);
            }
        });
    }

    async getSpi() {
        if (!this.response || !this.patient) return;
        await this.waitForAnalyze();
        this.isAnalyzing = true;
        let newShowPkms = this.showPkms;
        if (this.response) {
            const questionnaireSpiItem = Questionnaire.GetQuestionnaireItemByLinkId(this.questionnaire, 'risk_spi_sum');
            let spiItem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, "risk_spi_sum", false);
            if (questionnaireSpiItem && spiItem) {
                let spiValue = Fhir.QuestionnaireResponse.GetResponseItemValue(spiItem);
                if (!spiItem.answer || spiItem.answer.length === 0 || typeof spiValue === "undefined") {
                    try {
                        let result = await this.analyzeService.analyse(this.patient, this.response, false, false);

                        if (result && result.pkmsSum) {
                            spiItem.answer = [{
                                valueCoding: {
                                    code: String(result.pkmsSum || -1),
                                    display: Fhir.Tools.SpiToString(result.pkmsSum)
                                },
                            }];
                        }
                    } catch (e) {
                        console.warn(e.message || JSON.stringify(e));
                    }

                    Fhir.QuestionnaireResponse.GetResponseItemByLinkId(this.response, "risk_spi_sum", false);
                }

                this.isAnalyzing = false;
                if (spiItem) {
                    let spiValue = Fhir.QuestionnaireResponse.GetResponseItemValueInt(spiItem);
                    newShowPkms = spiValue <= AnalyzeService.PkmsRelevanceStart && spiValue > 1;
                    this.pkmsSum = spiValue;
                } else {
                    console.warn("No PKMS-Info found");
                    newShowPkms = false;
                }
            }
        }

        if (this.patient.isEncounterDuringPkmsPeriod) {
            this.taskQueue.queueTask(() => {
                this.showPkms = newShowPkms;
            });
        }
    }

    afterLoadedData() {
        super.afterLoadedData();

        BasicForm.pageTitle = this.questionnaire ? this.questionnaire.title : this.i18n.tr('assessment');
    }

    async afterLoaded() {
        super.afterLoaded();

        try {
            await this.checkForAutoGeneratedResponses();
            await this.recalcRisks(this.response, false);
        } catch (e) {
            console.warn(e.message || JSON.stringify(e));
        } finally {
            this.isLoading = false;
            this.taskQueue.queueTask(() => {
                window.setTimeout(async () => {
                    this.showPkms = false;
                    await this.getSpi();
                }, 250);
            });
        }
    }
}
